/* eslint-disable react/jsx-no-undef */
import React from 'react';
import OELink from '../../../core/components/general/OELink';
import { IMicroStrategyDossierChapter, IMicroStrategyDossierPage } from '../../entities/MicrostrategyDossierChapter';
import { setDossierSelectedPage } from '../../services/MicrostrategyDossierService';
declare global { var msLogin: any; }

interface IComponentInfo {
    page: IMicroStrategyDossierPage;
    dossier: any;
    pageIndex: number;
    chapterIndex: number;
    chapter: IMicroStrategyDossierChapter;
    currentIndex: string;
    setCurrentIndex: (i: string) => void;
    gotoPage: (chapter: IMicroStrategyDossierChapter, pageIndex: number) => void;
    isLink: boolean;
}

const MicrostrategyPageSection: React.FunctionComponent<IComponentInfo> = ({
    chapter, page, isLink, setCurrentIndex, currentIndex, dossier, pageIndex,
    chapterIndex, gotoPage
}) => {

    const onSelect = () => {
        setDossierSelectedPage(dossier, chapterIndex, pageIndex, onChapterSet);
    }

    const onChapterSet = () => {
        setCurrentIndex(`${chapterIndex}-${pageIndex}`);
        gotoPage(chapter, pageIndex);
    }

    return (
        <span onClick={onSelect} >
            {isLink && (
                <OELink data-rb-event-key={`group`} translate={false}
                    aria-controls={`reports-tabpane-group`} aria-selected="true"
                    className={`paginaton-link ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'paginaton-link-active' : ''}`}>
                    {`${page.name}`}
                </OELink>
            )}
            {!isLink && (
                <button role="tab" data-rb-event-key={`group`}
                    aria-controls={`reports-tabpane-group`} aria-selected="true"
                    className={`nav-item nav-link ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'active' : ''}`}>
                    {`${page.name}`}
                </button>
            )}
        </span>
    );
};

export default MicrostrategyPageSection;

