import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export interface IReportFlag extends IBaseAuditedEntity {
    id?: string;
    academicYear: string;
    academicYearLabel: string;
    reportId: string;
    report: string;
    districtId: string;
    district: string;
    schoolId: string;
    school: string;
    comments: string;
}

export const defaultReportFlag: IReportFlag = {
    academicYear: '', reportId: '', comments: '', academicYearLabel: '',
    district: '', school: '', districtId: '', schoolId: '', report: ''
};

export const getReportFlagList = (i: IBaseList<IReportFlag>): IReportFlag[] => {
    const d: IReportFlag[] = [];
    try {
        for (const v of i.items) {
            d.push(v);
        }
    }
    catch {
        console.log('error', 'Error occured reading report flags');
    }
    return d;
};
