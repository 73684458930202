import React from 'react';
import { displayHTMLContent } from '../../core/utilities/Miscellaneous';
import { IWidget } from '../entities/Widget';

interface IComponentInfo {
    item: IWidget;
}

const WidgetContent: React.FunctionComponent<IComponentInfo> = ({ item }) => {
    return (
        <div dangerouslySetInnerHTML={displayHTMLContent(item && item.content)} />
    );
};

export default WidgetContent;