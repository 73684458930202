import React, { useEffect, useState } from 'react';
import OEIcon from '../../../../core/components/general/OEIcon';
import { Icon } from '../../../../core/entities/Icon';
import { getReportConfigurationValue, ReportConfigurationType } from '../../../../reporting/entities/ReportConfiguration';
import { IReportList } from '../../../../reporting/entities/ReportList';
import { getSiteSetting, SiteSetting } from '../../../../site/entities/SiteSettings';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

export function replaceDoubleSlash(str: string): string {
    const secure: boolean = str.includes('https://');
    str = str.replace(secure ? 'https://' : 'http://', '');
    str = str.replace(/[- ]/g, '_').replace(/[,#!$%^&*;:{}=`~()]/g, '').replace(/ /g, '').replace(/_{2,}/g, '_');
    str = str.replace(/(\/\/)/g, (match, _, offset) => {
        if (str.indexOf('//') === offset) {
            return match;
        }
        return '/';
    });
    return `${secure ? 'https://' : 'http://'}${str}`.replace('///', '//');
}

interface IComponentInfo {
    report: IReportList;
    pageConfigruation: string;
    year: string;
}


const ReportDownload: React.FunctionComponent<IComponentInfo> = ({ report, year, pageConfigruation }) => {
    const [url] = useState<string>(getReportConfigurationValue(ReportConfigurationType.DataDownloadDirectory, pageConfigruation));
    const [prefix] = useState<string>(getReportConfigurationValue(ReportConfigurationType.DataDownloadPrefix, pageConfigruation));
    const [fileURL, setFileURL] = useState<string>('');

    useEffect(() => {
        setFileURL(getFileUrl());
        // eslint-disable-next-line
    }, [year]);

    const getFilename = () => {
        const filename: string = getReportConfigurationValue(ReportConfigurationType.ReportDownloadFilename, report.configuration) || report.title;
        const group: string = getReportConfigurationValue(ReportConfigurationType.ReportDownloadGroup, report.configuration);
        const s: string = year.length < 2 ? '24' : year.substring(year.length - 2, year.length);
        return `${prefix.replace('{{YEAR}}',s)}${group}${filename}.csv`;
    }

    const getFileUrl = () => {
        let fileURL = `${getSiteSetting(SiteSetting.MSDownloadPath)}${url}/${getFilename()}`;
        return replaceDoubleSlash(fileURL);
    }

    return (
        <div style={{ width: "fit-content" }} className='form-label m-t-10'>
            <a href={fileURL} className='form-label m-t-10'>
                <OEIcon className={"download-icon report-icon"} icon={Icon.Download} />
                <span className='fw-normal h6 m-l-5'>{`${report.title}`}</span>
                < br />
            </a>
        </div>
    );
};

export default ReportDownload;
