export enum Icon {
    Add = 'fas fa-plus',
    ArrowLeft = 'fas fa-arrow-left',
    ArrowRight = 'fas fa-arrow-right',
    Artifacts = 'fas fa-newspaper',
    Back = 'fas fa-chevron-left',
    Ban = 'fas fa-ban',
    CaretUp = 'fas fa-caret-up',
    CaretDown ='fas fa-caret-down',
    Checked = 'fas fa-check',
    CheckYes = 'fas fa-check text-success',
    CheckNo = 'fas fa-times text-danger',
    ChildrenNavigate = 'fas fa-caret-right',
    ChildrenOpen = 'fas fa-caret-down',
    Click = 'fas fa-mouse',
    Close = 'far fa-times-circle',
    Compile = 'fas fa-satellite-dish',
    ContentAdministration = 'fas fa-file-signature',
    CubeDefinition = 'fas fa-cubes',
    CubeFilters = 'fas fa-filter',
    Dashboard = 'fas fa-tachometer-alt',
    DatasetConnections = 'fas fa-route',
    Delete = 'fas fa-trash-alt',
    Dictionary = 'fas fa-book',
    Download = 'fas fa-download',
    Edit = 'fas fa-pencil-alt',
    EditHeader = 'fas fa-pen-square',
    Example = 'fas fa-vial',
    Excel = 'fas fa-file-excel excel-icon',
    Error = 'fas fa-exclamation-circle',
    Erase = 'fas fa-eraser',
    File = 'fas fa-file',
    FlagOff = 'fal fa-flag',
    FlagOn = 'fa fa-flag',
    Info = 'fas fa-info-circle',
    Link = 'fas fa-link',
    List = 'fas fa-list',
    Login = 'fas fa-sign-in-alt',
    Logout = 'fas fa-sign-out',
    Oops = 'fas fa-frown',
    Organization = 'fas fa-sitemap',
    Message = 'fas fa-sms',
    Minus = 'fas fa-minus',
    Modules = 'fa fa-sitemap',
    Page = 'fa fa-page',
    PageNotFound = 'fas fa-ban',
    PageBackward = 'fas fa-ellipsis-h',
    PageBackwardStep = 'fas fa-step-backward',
    PageForward = 'fas fa-ellipsis-h',
    PageForwardStep = 'fas fa-step-forward',
    PDF = 'fas fa-file-pdf pdf-icon',
    Permissions = 'fas fa-key',
    Preview = 'fas fa-eye',
    Refresh = 'fas fa-sync-alt',
    Report = 'fa fa-chart-line',
    ReportAdministration = 'fa fa-chart-area',
    ReportDescriptionLink = 'fal fa-info-circle',
    ReportGroup = 'fas fa-chart-bar',
    ReportType = 'fa fa-file-chart-column',
    Reset = 'fas fa-eraser',
    Role = 'fas fa-user-tag',
    Routing = 'fas fa-road',
    Run = 'fas fa-layer-group',
    Search = 'fas fa-search',
	Select = 'fas fa-hand-pointer',
    Server = 'fa fa-server',
    SiteAdministration = 'fas fa-tools',
    SiteMenu = 'fas fa-bars',
    Sort = 'fas fa-sort',
    SpinnerLarge = 'fas fa-spinner fa-pulse fa-2x fa-fw',
    SpinnerSmall = 'fas fa-spinner fa-pulse fa-fw',
    SpinnerExtraSmall = 'fas fa-spinner fa-pulse fa-xs fa-fw',
    Table = 'fas fa-table',
    TableSort = 'fas fa-fw fa-sort',
    TableSortAsc = 'fas fa-fw fa-sort-up',
    TableSortDesc = 'fas fa-fw fa-sort-down',
    ThumbsUp = 'fas fa-thumbs-up',
    UnauthorizedAccess = 'fas fa-key',
    Unchecked = 'fas fa-times',
    Upload = 'fas fa-upload',
    UserManagement = 'fas fa-user',
    View = 'far fa-eye'
}
