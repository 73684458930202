import { getSiteSetting, SiteSetting } from "../../site/entities/SiteSettings";

export const localStoragePersistentKey: string = 'NIMPER:';

export const reactLogout = (): any => {
    const url: string = getSiteSetting(SiteSetting.LogoutRedirect);
    updateCookie('XSRF-TOKEN', '');


    //clear out non persistent localStorage items
    for (const key in localStorage) {
        if (!key.startsWith(localStoragePersistentKey)) {
            localStorage.removeItem(key);
        }
    }
    redirectLogin(url);
};

export const getAuthorizationToken = (): any => {
    return getCookie('XSRF-TOKEN');
};

export const setAuthorizationToken = (expirationSeconds: number): any => {
    var expires = (new Date(Date.now() + (expirationSeconds * 1000))).toUTCString();
    document.cookie = `XSRF-TOKEN=${getAuthorizationToken}; expires=${expires}; path=/;`
};

export const getCookie = (name: string) => {
    let cookieValue: string = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const updateCookie = (name: string, value: string) => {
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookie = value;
                break;
            }
        }
    }
}

export const redirectLogin = (url?: string): any => {
    console.log('********************* UNAUTHORIZED *******************************');
    if (url) {
        window.location.href = url;
    } else {
        window.location.href = '/Account/Login?returnUrl=/LoginRedirect';
    }
};

