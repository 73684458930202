import React from 'react';
import { ICoreComponentInfo } from '../../../../site/components/OECoreComponent';
import MSEmbeddedPageReport from './MSEmbeddedPageReport';

const MSEmbeddedPage: React.FunctionComponent<ICoreComponentInfo> = () => {
    return (
        <MSEmbeddedPageReport />
    );
};

export default MSEmbeddedPage;
