import { useEffect, useState } from 'react';
import { JSX } from 'react/jsx-runtime';
import { processDeleteRequest } from '../../core/services/DeleteRequest';
import { processPostRequest } from '../../core/services/PostRequest';
import '../../css/alertSubscription.css';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { IAlertSubscription } from '../entities/AlertSubscription';
import { useDeleteForUnsubscribe, useGetAlertSubscriptions, usePostSubscribe } from '../services/AlertSubscriptionService';

const AlertSubscriptionsList: React.FunctionComponent<ICoreComponentInfo> = () => {
    const maxDisplayCount = 5;

    const { service: getAlertSubscriptionService } = useGetAlertSubscriptions();
    const { service: postUrlService,
        setAlertSubscription: urlPostSubscribe,
        setAlertSubscriptions: setPostAlertSubscriptionsCollection,
    } = usePostSubscribe();

    const { service: deleteUrlService,
        setAlertSubscription: urlDeleteUnsubscribe,
        setAlertSubscriptions: setDeleteAlertSubscriptionsCollection,
    } = useDeleteForUnsubscribe();

    const [alerts, setAlerts] = useState<IAlertSubscription[]>([]);

    const onClickSubscribe = (alert: IAlertSubscription) => {
        setPostAlertSubscriptionsCollection(alerts);
        urlPostSubscribe(alert);
    }

    const onClickUnsubscribe = (alert: IAlertSubscription) => {
        setDeleteAlertSubscriptionsCollection(alerts);
        urlDeleteUnsubscribe(alert);
    }

    useEffect(() => {
        processPostRequest({
            service: usePostSubscribe,
            errorMessage: 'Error occurred subscribing',
        });
    }, [postUrlService]);

    useEffect(() => {
        processDeleteRequest({
            service: useDeleteForUnsubscribe,
            errorMessage: 'Error occurred subscribing',
        });
    }, [deleteUrlService]);

    useEffect(() => {
        if (getAlertSubscriptionService.result) {
            setAlerts(getAlertSubscriptionService.result.items);
        }
    }, [getAlertSubscriptionService]);

    const generateRows = () => {
        var iteration = 0;
        var list: JSX.Element[] = [];
        alerts.map((item, index) => {
            if (iteration < maxDisplayCount) {
                iteration++;

                const subscriptionText = item.isSubscribed ? "unsubscribe" : "subscribe";
                const className = `alertSubscriptionWidgetContainer ${item.isSubscribed ? "btn-xs btn-danger" : "btn-xs btn-success"}`;
                const onClickMethod = item.isSubscribed ?
                    () => onClickUnsubscribe(item)
                    :
                    () => onClickSubscribe(item);

                list.push(<tr key={index}>
                    <td>{item.eventName}</td>
                    <td>
                        <button className={className} onClick={onClickMethod}>{subscriptionText}</button>
                    </td>
                </tr >);
            }
            return item;
        });
        return list;
    }

    return (
        <div className="alertSubscriptionWidgetContainer col-12">
            <div className="header">
                Notification Subscription
            </div>
            <table className='col-12'>
                <thead>
                    <tr>
                        <th>Alert Name</th>
                        <th>Subscribed</th>
                    </tr>
                </thead>
                <tbody>
                    {generateRows()}
                </tbody>
            </table>
        </div>
    );
}
export default AlertSubscriptionsList;