import React, { useEffect, useState } from 'react';
import OEFilter from '../../../core/components/filter/OEFilter';
import { IFilter } from '../../../core/components/filter/entities/Filter';
import OEHeading from '../../../core/components/general/OEHeading';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OENotification from '../../../core/components/messaging/OENotification';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../../core/components/messaging/entities/Notification';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn, ModifiedColumns } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { Icon } from '../../../core/entities/Icon';
import { processPostRequest } from '../../../core/services/PostRequest';
import { ICoreComponentInfo } from '../../../site/components/OECoreComponent';
import { defaultGlossary, IGlossary } from '../../entities/Glossary';
import { defaultPaginationFilter, IUserPaginationFilter } from '../../entities/User';
import { getGlossaryList, useDeleteGlossary, useGetGlossaries, useImportGlossary } from '../../services/GlossaryService';
import GlossaryFormik from './GlossaryFormik';

enum ModalTypes {
    None = 1,
    Edit,
    Permissions,
}

const GlossaryTable: React.FunctionComponent<ICoreComponentInfo> = () => {

    const { service, setParams } = useGetGlossaries();
    const { service: deleteService, setDeleteId } = useDeleteGlossary();
    const { service: importService, setGlossaryImport } = useImportGlossary();

    const [items, setItems] = useState<IGlossary[]>([]);
    const [filterList, setFilterList] = useState<IGlossary[]>([]);
    const [item, setItem] = useState<IGlossary>(defaultGlossary);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [paginationParams, setPaginationParams] = useState<IUserPaginationFilter>(defaultPaginationFilter);
    const [showFileImport, setShowFileImport] = useState(true);

    useEffect(() => {
        if (service && service.result && service.result.totalCount && service.result.totalCount > 0) {
            setItems(getGlossaryList(service.result));
        } else {
            setItems([]);
            setFilterList([]);
        }
    }, [service]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: deleteService,
            successAction: refreshItems,
            successMessage: 'Glossary Deleted Successfully',
            errorMessage: 'Error occurred deleting Glossary',
        });
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: importService,
            successAction: refreshItems,
            successMessage: 'Glossary Imported Successfully',
            errorMessage: 'Error occurred on Importing Glossary',
        });
        if (importService.isFinished) {
            if (importService.isSuccess) {
                setShowFileImport(false);
            }
        }
        // eslint-disable-next-line
    }, [importService])

    const refreshItems = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onEdit = (i: IGlossary) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
        setParams({ ...paginationParams });
    };

    const onConfirmDelete = (i: IGlossary) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Delete Glossary?',
            message: `Are you sure you want to delete the glossary?`,
            onOk: onDelete,
            onCancel: onCancel,
        });
    };

    const readFileDataAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result);
                setGlossaryImport({
                    webFileInfo: {
                        name: file.name,
                        contentType: file.type,
                        data: event.target.result.split("base64,")[1]
                    },
                    importAction: 2
                })
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'term', idNewLine: 'group', name: 'Term', sort: true, type: ColumnType.Link },
        { id: 'definition', name: 'Definition', sort: true, type: ColumnType.String, maxLength: 1000 },
        ...ModifiedColumns,
    ];

    const onAdd = () => {
        setItem(defaultGlossary);
        setShowModal(ModalTypes.Edit);
    };

    const filter: IFilter = {
        name: 'glossaryAdmin',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['group', 'term'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by Group, Term' },
        ],
    };

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Glossary Administration" icon={Icon.UserManagement} />
            <OENotification setNotification={setNotification} notification={notification} />
            <OEFilter singleLine={true} refresh={() => setParams({ ...paginationParams })} onFilter={(value) => {
                setPaginationParams({ ...paginationParams, filter: value });
                setParams({ ...paginationParams, filter: value });
            }} className="m-t-0" items={items} filter={filter} setFilterList={setFilterList} />
            <OETable
                data={filterList}
                columns={columns}
                showPagination={filterList.length > 10}
                showExport={true}
                showImport={true}
                showFileImport={showFileImport}
                loading={service.isInProgress}
                loadingMessage="Loading Glossary"
                defaultSort="textCol"
                defaultPageSize={25}
                noDataMessage={`No glossary exists.`}
                includeRowNumber={true}
                actions={[
                    { icon: Icon.Add, text: 'Add New Term', action: onAdd }
                ]}
                onImport={(file) => readFileDataAsBase64(file)}
            />
            {showModal === ModalTypes.Edit && (
                <GlossaryFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}
            <OEConfirmation {...confirmation} />
        </div>
    );
};

export default GlossaryTable;