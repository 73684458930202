import { IBreadcrumb } from "../../../core/components/breadcrumbs/entities/Breadcrumb";
import { defaultCoreRouteProperties, ICoreRouteProperties } from "../../entities/CoreRouteProperties";

export interface ICustomRouteProperties extends ICoreRouteProperties {

}

export const defaultCustomRouteProperties: ICoreRouteProperties = {
    ...defaultCoreRouteProperties
};

export const appRootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Home' }];
