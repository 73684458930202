import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DictionaryIframe } from '../../dictionaries/components/DictionaryIframe';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { useGetDcForm } from '../services/DcFormListService';

export const DcFormsView: React.FunctionComponent<ICoreComponentInfo> = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const type = searchParams.get('type');
    const form = type === '1' ? 'ViewForm' : 'ReviewForm'

    const [url, setUrl] = useState<string>('');
    const { service: dcFormsService, setItemId } = useGetDcForm();

    useEffect(() => {
        if (!id || !form || !dcFormsService.result) {
            return;
        }

        // if form is locked don't show it
        if ((dcFormsService.result.status === 4 && form === 'ViewForm')) {
            navigate('/dc-forms');
        } else {
            const formId = dcFormsService.result.formId;
            setUrl(`/Forms/${formId}/${form}?dcFormId=${id}`);
        }
    }, [id, form, setUrl, dcFormsService, navigate]);

    useEffect(() => {
        if (!id) {
            return
        }

        setItemId(id);
    }, [id, setItemId]);

    return (
        <>
            {url !== null && (
                <DictionaryIframe
                    title=""
                    url={url}
                />
            )}
        </>
    );
};
