import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { OEInputType } from '../../../core/components/form/OEInput';
import { IReference } from '../../entities/Reference';
import { useCreateReference, usePutReference } from '../../services/ReferenceService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IReference> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
           {<OEFormInput
                label="Title" name="title" value={values.title} required={true} maxLength={120}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String}
            />}
            <OEFormInput
                label="Definition" name="definition" value={values.definition} maxLength={1000}
                errors={errors} touched={touched} columns={labelColumns} required={true}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />
            <OEFormInput
                label="Sort Order" name="sortOrder" value={values.sortOrder} maxLength={5}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.Number}
            />
            <OEFormInput
                label="URL" name="url" value={values.url} maxLength={120}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IReference>().shape({
    title: Yup.string().required('Title is required').min(2).max(120).nullable(),
    definition: Yup.string().required('Definition is required').min(2).max(1000).nullable(),
    url: Yup.string().required('Title is required').min(2).max(120).nullable(),
});

interface IFormikInfo {
    item: IReference;
    onCancel: () => void;
    onSave: () => void;
}

const ReferenceFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {

    const { service, setReference } = usePutReference();
    const { service: createService, setReference: setCreateReference } = useCreateReference();

    const [localValues, setLocalValues] = useState<IReference>(item);

    useEffect(() => {
        setLocalValues(item);
    }, [item]);

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={localValues}
            labelColumns={labelColumns}
            title={`${item.id ? `Edit Reference: ${item.term}` : `Add New Reference`}`}
            progressMessage={ProgressMessages.Reference}
            successMessage={SuccessMessages.Reference}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            onSave={onSave}
            setItem={item.id ? setReference : setCreateReference}
            service={item.id ? service : createService}
        />
    );
};

export default ReferenceFormik;
