import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import OEMessage from '../../../../core/components/messaging/OEMessage';
import OENotification from '../../../../core/components/messaging/OENotification';
import OESpinner, { SpinnerStyle } from '../../../../core/components/messaging/OESpinner';
import { INotification, defaultNotification } from '../../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../../core/components/messaging/enums/InformationMessages';
import useWindowDimensions from '../../../../core/hooks/useWindowDimensions';
import { displayHTMLContent } from '../../../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../../../core/utilities/String';
import { navigateToPageReactRouter, parseQueryString, updateURLParameter } from '../../../../core/utilities/URL';
import { IReport, canDebugReport, defaultReport } from '../../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationGroupStyles, ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage } from '../../../../reporting/entities/ReportProcessing';
import { useGetReportPageTiered } from '../../../../reporting/services/ReportPageService';
import { useGetReport } from '../../../../reporting/services/ReportService';
import { ICoreComponentInfo } from '../../../../site/components/OECoreComponent';
import { SiteSetting, getSiteSetting, saveSiteSetting } from '../../../../site/entities/SiteSettings';
import { IDossierSettings, defaultDossierSettings } from '../../../entities/MicroStrategyDossier';
import { IMicroStrategyFilter } from '../../../entities/MicroStrategyFilters';
import { ICubeAttribute } from '../../../entities/api/CubeResponse';
import AboutSection from '../../common/AboutSection';
import Cube from '../../common/Cube';
import DebugMessage from '../../common/DebugMessage';
import PageFilters from '../../common/PageFilters';
import Report from '../../common/Report';
import TokenValidation from '../../common/TokenValidation';
import SectionGroups from './SectionGroups';
import SectionTitle from './SectionTitle';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSEmbeddedTierThreeTest: React.FunctionComponent<ICoreComponentInfo> = () => {
    const params: any = parseQueryString();
    const [pageParameterId] = useState(params['pid'] || '');
    const contentRef = useRef<any>();
    const headerRef = useRef<any>();
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: pageService, setPageId } = useGetReportPageTiered('');
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const [debug] = useState<boolean>(canDebugReport);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [section, setSection] = useState<IReportGroup>(defaultReportGroup);
    const [defaultSection, setDefaultSection] = useState<IReportGroup>(defaultReportGroup);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [cubeId, setCubeId] = useState<string>('');
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);

    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [configurations, setConfigurations] = useState<IReportConfigurations>(defaultReportConfigurations);
    const [validToken, setValidToken] = useState<boolean>(false);
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [report, setReport] = useState<IReport>(defaultReport);

    const [widthContent, setWidthContent] = useState<number>(0);
    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            window.onresize = onWindowResized;
            const storeSize = getSiteSetting(SiteSetting.ReportContentWidth);
            if (storeSize) {
                setWidthContent(parseInt(storeSize))
            } else {
                // Default value 1280
                const currentWidth = width - 290 > 1280 ? 1280 : width - 290;
                setWidthContent(currentWidth)
            }

            if (!contentRef.current) return;
            const resizeObserver = new ResizeObserver(() => {
                if (contentRef && contentRef.current) {
                    saveSiteSetting(SiteSetting.ReportContentWidth, contentRef.current.clientWidth);
                }
            });
            resizeObserver.observe(contentRef.current);
            return () => resizeObserver.disconnect();
        }
        catch {
            console.log('resizing failed');
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        if (processingStep !== ProcessingPage.FatalError) {
            setError('');
        }
        switch (processingStep) {
            case ProcessingPage.PageStart:
                setProcessingStep(ProcessingPage.PageLoad);
                break;

            case ProcessingPage.PageLoad:
                setPageId(pageParameterId);
                break;

            case ProcessingPage.PageLoaded:
                try {
                    setProcessingStep(ProcessingPage.ReportLoad);
                    setPageSettings({
                        ...defaultDossierSettings,
                        collapsibleSections: getReportConfigurationValue(ReportConfigurationType.CollapsibleSections, configurations.pageConfiguration)
                    });
                }
                catch {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`Cube could not be setup based on the first report for this page.`);
                }
                break;

            case ProcessingPage.ReportLoad:
                let reportId: string = '';

                for (const c of page.groups[0].groups[0].groups) {
                    if (c.reports.length > 0) {
                        reportId = c.reports[0].reportId;
                        break;
                    }
                }

                if (reportId === '') {
                    setProcessingStep(ProcessingPage.FatalError);
                    const sectionTitle: string = page.groups[0].groups[0].title;
                    setError(`There are no reports setup for the section <b><i>${sectionTitle}</b></i>`);
                } else {

                    if (cubeAttributes.length === 0) {
                        setGetReportId(reportId);
                        setProcessingStep(ProcessingPage.PageFiltersLoad);
                    }
                    else {
                        setProcessingStep(ProcessingPage.SectionGroupsInitialize);
                    }
                }
                break;

            case ProcessingPage.PageFiltersLoaded:
                setProcessingStep(ProcessingPage.SectionLoad);
                break;

            case ProcessingPage.SectionLoad:
                if (!setSectionByID(params.sid)) {
                    onChangeSectionTitle(page.groups.filter(q => q.isActive)[0]);
                }
                break;

            case ProcessingPage.SectionLoaded:
                updateURLParameter("sid", section.id);
                break;

            case ProcessingPage.GroupLoad:
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                setProcessingStep(ProcessingPage.Complete);
                break;


            case ProcessingPage.SectionGroupsInitialized:
            case ProcessingPage.PageFiltersUpdated:
                setProcessingStep(ProcessingPage.Complete);
                break;

            case ProcessingPage.Complete:
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.PageStart);
        }
        // eslint-disable-next-line
    }, [validToken]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            setProcessingStep(ProcessingPage.PageLoaded);
            setConfigurations({ ...configurations, pageConfiguration: page.configuration });
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (section.id !== '') {
            setProcessingStep(ProcessingPage.SectionLoaded);
            setConfigurations({ ...configurations, sectionConfiguration: section.configuration });
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (group.id !== '') {
            if (!getReportConfigurationValue(ReportConfigurationType.MultipleReports, group.configuration)) {
                if (group.reports.length > 1) {
                    group.reports = [{ ...group.reports[0] }];
                }
            }
            setProcessingStep(ProcessingPage.GroupLoad);
            setConfigurations({ ...configurations, groupConfiguration: group.configuration });
        }
        // eslint-disable-next-line
    }, [group]);

    const loadGroup = () => {
        updateURLParameter('gid', group.id);
        if (group.reports.length > 0) {
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, configurations.groupConfiguration));
        }
        setPageSettings({
            ...pageSettings,
            hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.pageConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.sectionConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.groupConfiguration),
        });
        setProcessingStep(processingStep === ProcessingPage.SectionGroupsInitialize ? ProcessingPage.SectionGroupsInitialized : ProcessingPage.GroupLoaded);
    }

    const onWindowResized = () => {
        onUpdateHeight();
    }

    const clear = () => {
        setSelectedGroup('')
    }

    const onUpdateHeight = () => {
        if (headerRef.current) {
            let h: number =
                headerRef.current.getBoundingClientRect().height
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 16)
                + headerRef.current.offsetTop + 55
                ;

            // subrtract height of tabs
            if (getReportConfigurationValue(ReportConfigurationType.GroupStyle, configurations.sectionConfiguration) === ReportConfigurationGroupStyles.Tabs) {
                h = h - 40;
            }

            // subtract height of report title
            if (group.reports.length > 1) {
                h = h - 20;
            }
            setReportHeight(h);
            //    setNotification({ duration: 10000, message: `report top ${headerRef.current.getBoundingClientRect().height}, 
            //    footer ${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}, header ${headerRef.current.offsetTop}, totalHeight ${h}`, type: 'info' });
        }
    }

    const setSectionByID = (id: string): boolean => {
        for (const sh of page.groups.filter(q => q.isActive)) {
            for (const sl of sh.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, sl.configuration)) {
                    setConfigurations({ ...configurations, sectionTitleConfiguration: sh.configuration });
                    setDefaultSection(sl);
                }
                if (sl.id === id) {
                    setConfigurations({ ...configurations, sectionTitleConfiguration: sh.configuration });
                    setSection(sl);
                    setSelectedGroup(sh.id);
                    return true;
                }
            }
        }
        return false;
    }

    const onChangeSection = (i: IReportGroup) => {
        updateURLParameter('gid', '');
        setGroup(defaultReportGroup);
        setSection(i);
    }

    const onChangeSectionTitle = (i: IReportGroup) => {
        if (i.title === 'Home') {
            navigateToPageReactRouter(navigate, '/home')
        }
        setConfigurations({ ...configurations, sectionTitleConfiguration: i.configuration });
        if (selectedGroup !== i.id) {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    setDefaultSection(c);
                    break;
                }
            }
            setSelectedGroup(i.id);
            onChangeSection(i.groups.filter(q => q.isActive)[0]);
        }
        else {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    onChangeSection(c);
                    break;
                }
            }
        }
    }

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    const setActiveGroup = (i: IReportGroup) => {
        setGroup(i);
    }

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
        console.log(debugMessage);
    }

    const setCubeError = (e: string) => {
        setError(`Cube Load Error: ${e}`);
        setProcessingStep(ProcessingPage.FatalError);
    }

    return (
        <div className="report-embedded m-b-0">
            <OENotification setNotification={setNotification} notification={notiication} />
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            <DebugMessage debug={debug}>Page: {processingStep}</DebugMessage>

            <Cube
                validToken={validToken}
                setCube={setCube}
                report={report}
                setNotification={setNotification}
                cubeId={cubeId}
                setError={setCubeError}
                setDebugMessage={setDebugMessage}
            />

            {pageParameterId && pageParameterId !== '' && (
                <>
                    <div className="three-tier">
                        <div className="left-nav">
                            {[...page.groups].filter(q => q.isActive).map((item, index) =>
                                <Fragment key={index} >
                                    <SectionTitle
                                        onClick={onChangeSectionTitle}
                                        onChangeSection={onChangeSection}
                                        selected={item.id === selectedGroup}
                                        defaultSection={defaultSection}
                                        selectedSection={section.id}
                                        item={item}
                                        collapsible={pageSettings.collapsibleSections}
                                        clear={clear}
                                    />
                                </Fragment>
                            )}
                        </div>

                        <div className='content'>
                            <div className='content-inner' ref={contentRef} style={{ width: widthContent }}>
                                <div ref={headerRef} >
                                    <PageFilters
                                        setNotification={setNotification}
                                        pageProcessingStep={processingStep}
                                        page={page}
                                        report={report}
                                        setPageProcessingStep={setProcessingStep}
                                        setPageFilters={setPageFilters}
                                        cubeAttributes={cubeAttributes}
                                        setDebugMessage={setDebugMessage}
                                    />
                                    {processingStep !== ProcessingPage.FatalError && section.groups.filter(q => q.isActive).length > 0 && (
                                        <SectionGroups
                                            pageFilters={pageFilters}
                                            section={section}
                                            activeGroup={group}
                                            setActiveGroup={setActiveGroup}
                                        />
                                    )}
                                </div>
                                {processingStep === ProcessingPage.FatalError && (
                                    <OEMessage
                                        className="report-errors"
                                        hideDismissable={true}
                                        message={error}
                                        type={MessageType.Error}
                                    />
                                )}
                                {processingStep !== ProcessingPage.FatalError && (
                                    <div ref={reportRef} className="report-title">
                                        {group.id !== '' && processingStep === ProcessingPage.Complete && (
                                            <>
                                                {group.groupType === ReportGroupType.AboutPage && (
                                                    <AboutSection group={group} />
                                                )}
                                                {group.reports.filter(q => q.isActive).map((item4, index4) =>
                                                    <Report
                                                        key={index4}
                                                        reportId={item4.reportId}
                                                        setNotification={setNotification}
                                                        pageFilters={pageFilters}
                                                        pageProcessingStep={processingStep}
                                                        divId={`report${item4.reportId}`}
                                                        configurations={{ ...configurations, reportConfiguration: item4.configuration }}
                                                        pageSettings={pageSettings}
                                                        reportHeight={reportHeight}
                                                        groupType={group.groupType}
                                                        multipleReports={getReportConfigurationValue(ReportConfigurationType.MultipleReports, configurations.groupConfiguration)}
                                                    />

                                                )}

                                                {footer && (
                                                    <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                                )}
                                            </>
                                        )}
                                        {group.id !== '' && processingStep !== ProcessingPage.Complete && (
                                            <OESpinner message="Loading Report" oeStyle={SpinnerStyle.Small} />
                                        )}
                                        {section.groups.length === 0 && section.id !== '' && (
                                            <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No groups have been set up for ${section.title} `} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div >
    );
};

export default MSEmbeddedTierThreeTest;
