import { CSSProperties } from 'react';
import { ButtonStyle } from '../../form/OEButton';
import { IColumnAction } from './ColumnAction';

export const getColumnStyle = (column: IColumn): CSSProperties => {
    return {
        width: `${column.width}`
    };
};

export interface IColumn {
    name?: string;
    nameIcon?: string;
    id: string;
    idNewLine?: string;
    idNewLine2?: string;
    type: string;
    format?: string;
    percision?: number;
    width?: string;
    align?: string;
    sort?: boolean;
    sortColumn?: string;
    displayHTML?: boolean;
    maxLength?: number;
    actions?: IColumnAction[];
    className?: string;
    classNameColumn?: string;
    badgeClass?: string;
    buttonStyle?: ButtonStyle;
    onClick?: (item: any) => void;
    onSelect?: (item: any, checked: boolean) => void;
    helpText?: string;
    filter?: boolean;
    condition?: string;
    notCondition?: boolean;
    hideZero?: boolean;
    text?: string;
    icon?: string;
    iconColumn?: string;
    timezone?: string;
    subTotal?: boolean;
    subTotalPrefix?: string;
    values?: any[];
    target?: string;
    baseURL?: string;
}

export enum ColumnType {
    Actions = 'AC',
    Boolean = 'BO',
    Button = 'BU',
    Check = 'CH',
    Checkbox = 'CB',
    Currency = 'CU',
    Date = 'DA',
    Decimal = 'DE',
    Empty = 'EM',
    ID = 'ID',
    Icon = 'IC',
    Image = 'IM',
    Integer = 'IN',
    Link = 'LI',
    NavigationLink = 'NL',
    Percentage = 'PE',
    PercentageExact = 'PX',
    RowNumber = 'RN',
    String = 'ST',
    StringArray = 'SA',
    StringSelector = 'SS',
    Text = 'TE',
    Time = 'TI'
}

export const ModifiedColumns: IColumn[] = [
    { id: 'creationTime', name: 'Created', sort: true, type: ColumnType.Date, format:'mm/dd/yyyy st', width: '150px', className:'small' },
    { id: 'lastModificationTime', name: 'Last Modified', sort: true, type: ColumnType.Date, format: 'mm/dd/yyyy st', width: '150px', className: 'small' },
];

