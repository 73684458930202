import { getSiteSetting, SiteSetting } from "../../site/entities/SiteSettings";

export interface IMicrostrategyPublicLogin {
    path: string;
    lang: string;
    mode: number;
    username: string;
    password: string;
}

export const defaultMicrostrategyPublicLogin: IMicrostrategyPublicLogin = {
    lang: 'en', mode: 1, username: '', password: '', path: ''
};

export const getMicrostrategyPublicLogin = (lang: string, cs: string): IMicrostrategyPublicLogin => {
    const i: IMicrostrategyPublicLogin = { ...defaultMicrostrategyPublicLogin };

    try {
        i.path = `${getSiteSetting(SiteSetting.MSPublicPath)}/api/auth/login`;
        for (const s of cs.split(',')) {
            const v: string[] = s.split(':');
            if (v.length > 1 && v[0] === lang) {
                i.lang = getSiteSetting(SiteSetting.MicrostrategyLanguage);
                i.mode = parseInt(v[1], 10);
                if (v.length > 2) { i.username = v[2]; }
                if (v.length > 3) { i.password = v[3]; }
            }
            else {
                console.log(`Error parsing public login ${cs} - ${lang} `);
            }
        }
    }
    catch {
        console.log(`Error parsing public login ${cs} - ${lang} `);
    }

    return i;
}

