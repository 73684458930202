import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { IOrganizationType } from '../entities/OrganizationType';
import { IRole } from '../entities/Role';
import { useCreateRole, usePutRole } from '../services/RoleService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
    lookupTables: IOrganizationType[];
}

const Form: React.FunctionComponent<FormikProps<IRole> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {

    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name} autoFocus={true}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Display Name" name="displayName" value={values.displayName} autoFocus={true}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormDropdown
                label="Organization Type" name="typeId" value={values.typeId} autoFocus={true}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue}
                values={lookupTables}
            />

        </>
    );
};

const ValidationScheme = Yup.object<IRole>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
    displayName: Yup.string().required('Display Name is required').min(2).max(250).nullable(),
    typeId: Yup.number().min(1, 'Type is required').nullable(),
});

interface IFormikInfo {
    item: IRole;
    onCancel: () => void;
    onSave: () => void;
    types: IOrganizationType[];
}

const RoleFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave, types }) => {
    const { service, setRole } = usePutRole();
    const { service: createService, setRole: setCreateRoute } = useCreateRole();

    return (
        <OEFormikModal
            item={item}
            labelColumns={labelColumns}
            title={`${item.id !== '' ? `Edit Role: ${item.name}` : `Add New Role`}`}
            progressMessage={ProgressMessages.Role}
            successMessage={SuccessMessages.Role}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            lookupTables={types}
            onCancel={onCancel}
            setItem={item.id !== '' ? setRole : setCreateRoute}
            service={item.id !== '' ? service : createService}
        />
    );
};

export default RoleFormik;
