import React, { useEffect, useState } from 'react';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import OEMessage from '../../core/components/messaging/OEMessage';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import { parseQueryString } from '../../core/utilities/URL';
import { IReport, defaultReport } from '../../reporting/entities/Report';
import { IReportGroup, defaultReportGroup } from '../../reporting/entities/ReportGroup';
import { IReportList } from '../../reporting/entities/ReportList';
import { IReportPage, defaultReportPage } from '../../reporting/entities/ReportPage';
import { useGetReportPageTiered } from '../../reporting/services/ReportPageService';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import BIEmbeddedReport from './BIEmbeddedReport';
import SectionGroup from './SectionGroup';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const BIEmbeddedTierOne: React.FunctionComponent<ICoreComponentInfo> = () => {
    const params: any = parseQueryString();
    const [pageParameterId] = useState(params['pid'] || '');

    const { service: pageService, setPageId } = useGetReportPageTiered(''); // Step 2 - get the page information

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [report, setReport] = useState<IReport>(defaultReport);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        setPageId(pageParameterId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (pageService.result) {
            setupPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            if (!setGroupByID(params.gid)) {
                setGroup(page.groups[0]);
            }
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (group.reports.length > 0) {
            const i: IReportList = group.reports[0];
            setReport({ ...defaultReport, id: i.reportId, title: i.title, description: i.description });
        }
        else {
            setReport(defaultReport);
        }
        // eslint-disable-next-line
    }, [group]);

    const setupPage = (p: IReportPage) => {
        p.groups = p.groups.filter(q => q.isActive);
        for (const g of p.groups) {
            g.reports = g.reports.filter(q => q.isActive);
        }
        setPage(p);
    }

    const setGroupByID = (id: string): boolean => {
        for (const g of page.groups) {
            if (g.id === id) {
                setGroup(g);
                return true;
            }
        }
        return false;
    }

    const onChangeGroup = (i: IReportGroup) => {
        setGroup(i);
    }

    return (
        <div className="report-embedded m-b-0 three-tier">
            <OENotification setNotification={setNotification} notification={notiication} />
            <div className="content" >
                {page.id !== '' && (
                    <div style={{ margin: '5px' }} >
                        <OERow >
                            <OECol sm={6}>
                                <h4 >{page.name}</h4>
                            </OECol>
                            <OECol sm={6}>
                                <div className="pull-right" >{page.description}</div>
                            </OECol>
                        </OERow>

                        <nav className="fst-italic small m-b-0 nav nav-tabs mb-3 nav nav-tabs" role="tablist">
                            {page.groups.filter(q => q.isActive).map((g, index) =>
                                <SectionGroup
                                    key={g.id}
                                    index={index}
                                    group={g}
                                    selected={group.id === g.id}
                                    onClick={onChangeGroup}
                                />
                            )}
                        </nav>

                        {group.reports.length === 0 && (
                            <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No report have been set up for ${group.title} `} />
                        )}

                        <BIEmbeddedReport configuration={report.configuration} report={report} />
                    </div>
                )}
            </div>
        </div >
    );
};

export default BIEmbeddedTierOne;
