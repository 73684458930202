import React, { useEffect, useState } from 'react';
import { parseQueryString } from '../../../../core/utilities/URL';
import { defaultReportPage, IReportPage } from '../../../../reporting/entities/ReportPage';
import { useGetReportPageDataDownload } from '../../../../reporting/services/ReportPageService';
import { ICoreComponentInfo } from '../../../../site/components/OECoreComponent';
import { appRootBreadcrumbs } from '../../../../site/custom/entities/CustomRouteProperties';
import GroupList from './GroupList';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSDataDownload: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties, setRouteProperties }) => {
    const params: any = parseQueryString();
    const { service: pageService, setPageId } = useGetReportPageDataDownload('');

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageParameterId] = useState(params['pid'] || '');
    const [openSection, setOpenSection] = useState<any>([]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        setPageId(pageParameterId);
        // eslint-disable-next-line
    }, [pageParameterId]);

    useEffect(() => {
        if (routeProperties.academicYearID) {
            // const n: string = `${routeProperties.corePageName} - ${routeProperties.academicYear}`;
            setRouteProperties({ ...routeProperties, breadcrumbs: [...appRootBreadcrumbs, { id: 1, name: routeProperties.corePageName }] });
        }
        // eslint-disable-next-line
    }, [routeProperties.academicYearID]);


    return (
        <div className='p30'>
            {/* <h2 className='default-title-body'>{`Datasets for ${routeProperties.academicYear}`}</h2> */}
            <ul className='no-p'>
                {page.groups.filter(q => q.isActive).map((sl) =>
                    <GroupList
                        level={0}
                        parentGroups={page.groups.length}
                        key={sl.id}
                        group={sl}
                        year={routeProperties.academicYearID}
                        pageConfigruation={page.configuration}
                        openSection={openSection} setOpenSection={(id) => {
                            if (openSection.includes(id)) {
                                let filteredSection = openSection.filter((section: string) => section !== id);
                                setOpenSection(filteredSection);
                            } else setOpenSection([...openSection, id])
                        }} />
                )}
            </ul>
            {/* {pageParameterId} */}
        </div>
    );
};

export default MSDataDownload;
