import React, { Children, useState } from 'react';
import ChatBtn from '../../chat/components/ChatBtn';
import ChatWidget from '../../chat/components/ChatWidget';
import { SessionController } from '../components/SessionController';
import SiteMenu from '../components/SiteMenu';
import { IRoute } from '../entities/Route';
import SiteFooter from './components/SiteFooter';
import { ICustomRouteProperties } from './entities/CustomRouteProperties';
import SkipControl from "../components/SkipControl";

export interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
    initializing: boolean;
}

interface IComponentInfo {
    children?: any;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children, routes, routeProperties, setRouteProperties }) => {
    const [logout, setLogout] = useState<boolean>(false);
    const [showChat, setShowChat] = useState(false);

    const onLogout = () => {
        setLogout(true);
    }

    return (
        <SessionController settings={routeProperties.settings} logout={logout} >
            <main className="main" data-selected-menu={localStorage.getItem('nimbleSelectedMenu')}>
                <SkipControl />
                <SiteMenu settings={routeProperties.settings} maintenance={routeProperties.maintenance} onLogout={onLogout} />

                <div className="nd-body" id='main-content'>
                    {Children.map(children, (i) => i)}
                </div>

                <SiteFooter />

                {routeProperties.settings.chatbotEnabled && (
                    <>
                        <ChatBtn showChat={showChat} setShowChat={setShowChat} />
                        <ChatWidget showChat={showChat} setShowChat={setShowChat} />
                    </>
                )}
            </main>
        </SessionController>
    );
};

export default Layout;
