import React, { useEffect, useState } from 'react';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { getUrlParameters } from '../../core/components/parameters/entities/OEParameter';
import UserProfile from '../../security/components/UserProfile';
import { IMenu } from '../entities/Menu';
import { ISiteMenu } from '../entities/SiteMenu';
import { ISiteSettings, SiteSetting, getSiteSetting, saveSiteSetting } from '../entities/SiteSettings';
import { useGetUserMenus } from '../services/MenuService';
import '../styles/NavMenu.css';
import Menu from './sitemenu/Menu';

export const saveRootMenu = (i: ISiteMenu) => {
    saveSiteSetting(SiteSetting.NimbleSelectedMenu, i.rootId.toString());
}

interface IComponent {
    onLogout: () => void;
    maintenance: boolean;
    settings: ISiteSettings;
}

const SiteMenu: React.FunctionComponent<IComponent> = ({ maintenance, settings }) => {
    const path: string = window.location.href;

    const { service } = useGetUserMenus();

    const [siteMenu, setSiteMenu] = useState<ISiteMenu[]>([]);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (service.result) {
            setSiteMenu(getChildren(service.result.items.filter(q => q.isActive), 0, 0));
        }
        // eslint-disable-next-line
    }, [service]);

    const getChildren = (p: IMenu[], pid: number, rid: number): ISiteMenu[] => {
        const l: ISiteMenu[] = [];
        for (const i of p.filter(q => q.parentId === pid)) {
            i.rootId = pid > 0 ? rid : i.id;
            const f: ISiteMenu = { ...i, children: getChildren(p, i.id, i.rootId) };
            f.childCount = f.children.length;
            if (f.parameters !== '') {
                f.path = `${f.path}${getUrlParameters(f.parameters)}`;
            }

            l.push(f);
        }
        return l;
    }

    const isActive = (m: ISiteMenu): boolean => {

        if (m.path === '/home' && path.endsWith(window.location.host + '/'))
            return true;

        if (getSiteSetting(SiteSetting.NimbleSelectedMenu) === m.rootId.toString() && !path.endsWith(window.location.host + '/'))
            return true;

        return false;
    }

    const inContainer = (): boolean => {
        if (path.includes("reportmsiframe")) {
            return false;
        }
        return true;
    }

    return (
        <>
            <OENotification setNotification={setNotification} notification={notification} />
            {siteMenu.length > 0 && (
                <>
                    <nav className="py-2 border-bottom">
                        <div className={`${inContainer() ? 'm-l-10' : 'm-l-10'} d-flex flex-wrap`}>
                            <a
                                href={settings.homePageLogoLink || '/'}
                                className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-decoration-none"
                                aria-label="Home page"
                            >
                                <img className="header-logo" src={`/images/header-logo.png?v=${Math.random() * 1000}`} alt="Home" title="Home" />
                                <span className="visually-hidden">Home</span>
                            </a>

                            {!maintenance && (
                                <UserProfile />
                            )}
                        </div>
                    </nav>
                    <header className="bg-dark text-white main-nav">
                        {!maintenance && (
                            <>
                                {siteMenu.filter(q => q.parentId === 0 && q.isActive).map((item, index) =>
                                    <ul className={`otis-menu ${index > 0 ? '' : 'm-l-40'}`} key={item.id} >
                                        <Menu item={item} isActive={isActive(item)} />
                                    </ul>
                                )}
                            </>
                        )}
                    </header>
                </>
            )}
        </>
    );

};

export default SiteMenu;
