import { useEffect, useState } from 'react';
import OEHeading from '../../core/components/general/OEHeading';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { processPostRequest } from '../../core/services/PostRequest';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { defaultArtifact, IArtifact } from '../entities/Artifact';
import { ArtifactType } from '../entities/ArtifactType';
import { GetArtifactsInput } from '../entities/GetArtifactsInput';
import artifactLookup from '../lookups/artifact-types.json';
import { usePostDatasetArtifact, usePutDatasetArtifact } from '../services/ArtifactDatasetService';
import { useDeleteArtifact, useGetArtifacts, usePostMicroStrategyArtifact, usePostSsrsArtifact, usePostUrlArtifact, usePutMicroStrategyArtifact, usePutSsrsArtifact, usePutUrlArtifact } from '../services/ArtifactService';
import ArtifactFilters from './ArtifactFilters';
import ArtifactFormik from './ArtifactFormik';

enum ModalTypes {
    None = 1,
    Edit,
    ReportSelector
}

const ArtifactList: React.FunctionComponent<ICoreComponentInfo> = () => {
    const { service: getArtifactService, doRefresh, setFilters, } = useGetArtifacts();
    const { service: deleteService, setDeleteId } = useDeleteArtifact();
    const { service: postDatasetService, setArtifact: datasetPostArtifact } = usePostDatasetArtifact();
    const { service: postMicroStrategyService, setArtifact: microStrategyPostArtifact } = usePostMicroStrategyArtifact();
    const { service: postSSrsService, setArtifact: ssrsPostArtifact } = usePostSsrsArtifact();
    const { service: postUrlService, setArtifact: urlPostArtifact } = usePostUrlArtifact();
    const { service: putDatasetService, setArtifact: datasetPutArtifact } = usePutDatasetArtifact();
    const { service: putMicroStrategyService, setArtifact: microStrategyPutArtifact } = usePutMicroStrategyArtifact();
    const { service: putSSrsService, setArtifact: ssrsPutArtifact } = usePutSsrsArtifact();
    const { service: putUrlService, setArtifact: urlPutArtifact } = usePutUrlArtifact();

    const [items, setItems] = useState<IArtifact[]>([]);
    const [item, setItem] = useState<IArtifact>({} as IArtifact);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (getArtifactService.result) {
            const artifacts = getArtifactService.result.items.map((a) => {
                return {
                    ...a,
                    artifactName:
                        artifactLookup.find((x) => x.id === a.artifactType)
                            ?.name || '',
                };
            });

            setItems(artifacts);
        }
    }, [getArtifactService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: deleteService,
            successAction: refreshItems,
            successMessage: 'Artifact Deleted Successfully',
            errorMessage: 'Error occurred deleting Artifact',
        });
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: deleteService,
            successAction: refreshItems,
            successMessage: 'Artifact Added Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [postDatasetService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: postSSrsService,
            successAction: refreshItems,
            successMessage: 'Artifact Added Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [postSSrsService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: postUrlService,
            successAction: refreshItems,
            successMessage: 'Artifact Added Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [postUrlService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: postMicroStrategyService,
            successAction: refreshItems,
            successMessage: 'Artifact Added Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [postMicroStrategyService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: putDatasetService,
            successAction: refreshItems,
            successMessage: 'Artifact Saved Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [putDatasetService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: putSSrsService,
            successAction: refreshItems,
            successMessage: 'Artifact Saved Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [putSSrsService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: putUrlService,
            successAction: refreshItems,
            successMessage: 'Artifact Saved Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [putUrlService]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: putMicroStrategyService,
            successAction: refreshItems,
            successMessage: 'Artifact Saved Successfully',
            errorMessage: 'Error occurred saving Artifact',
        });
        // eslint-disable-next-line
    }, [putMicroStrategyService]);

    const onSave = (artifact: IArtifact) => {
        artifact.artifactType = +artifact.artifactType;

        switch (artifact.artifactType) {
            case ArtifactType.Dataset:
                artifact.id === undefined
                    ? datasetPostArtifact(artifact)
                    : datasetPutArtifact(artifact);
                break;

            case ArtifactType.MicroStrategy:
                artifact.id === undefined
                    ? microStrategyPostArtifact(artifact)
                    : microStrategyPutArtifact(artifact);
                break;

            case ArtifactType.Ssrs:
                artifact.id === undefined
                    ? ssrsPostArtifact(artifact)
                    : ssrsPutArtifact(artifact);
                break;

            case ArtifactType.Url:
                artifact.id === undefined
                    ? urlPostArtifact(artifact)
                    : urlPutArtifact(artifact);
                break;

            default:
                setNotification({ ...defaultNotification, message: `OnSave: Invalid artifact type: ${artifact.artifactType}`, type: 'error' });
        }
    };

    const refreshItems = () => {
        doRefresh();
        setShowModal(ModalTypes.None);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem(defaultArtifact);
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IArtifact) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IArtifact) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Delete Artifact?',
            message: `Are you sure you want to delete the artifact?`,
            onOk: onDelete,
            onCancel: onCancel,
        });
    };

    const onFilter = (filters?: GetArtifactsInput) => {
        const { application, artifactType, displayName, isPublished } =
            filters || {};

        const filter: GetArtifactsInput = {
            application,
            artifactType,
            displayName,
            isPublished,
        };

        setFilters(filter);
        doRefresh();
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit Artifact' },
        {
            icon: Icon.Delete,
            onClick: onConfirmDelete,
            helpText: 'Delete Artifact',
        },
    ];

    const columns: IColumn[] = [
        { id: 'displayName', name: 'Display Name', sort: true, type: ColumnType.String, onClick: onEdit, },
        { id: 'application', name: 'Application', sort: false, type: ColumnType.String, },
        { id: 'artifactName', name: 'Artifact Type', sort: true, type: ColumnType.String, },
        { id: 'isPublished', name: 'Published', sort: true, type: ColumnType.Boolean, },
        { id: 'description', name: 'Description', sort: false, type: ColumnType.String, displayHTML: true },
        { actions, id: 'Actions', name: '', sort: false, type: ColumnType.Actions, },
    ];

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Artifacts" icon={Icon.Artifacts} />
            <OENotification setNotification={setNotification} notification={notification} />
            <ArtifactFilters onFilter={onFilter} onAdd={onAdd} />

            <OETable
                loading={getArtifactService.isInProgress}
                loadingMessage="Loading Artifacts"
                data={items}
                columns={columns}
                showPagination={(items?.length || 0) > 10}
                defaultSort="name"
                noDataMessage="No Artifacts exist"
                defaultPageSize={10}
            />

            {showModal === ModalTypes.Edit && (
                <ArtifactFormik
                    item={item}
                    onCancel={onCancel}
                    onSave={onSave}
                />
            )}

            <OEConfirmation {...confirmation} />
        </div>
    );
};

export default ArtifactList;
