import React from 'react';
import { displayHTMLContent } from '../../core/utilities/Miscellaneous';
import { IWidget } from '../entities/Widget';

interface IComponentInfo {
    items: IWidget[];
    code: string;
}

const WidgetContentByCode: React.FunctionComponent<IComponentInfo> = ({ items, code }) => {
    return (
        <>
            {items.filter(q => q.code === code).length > 0 && (
                <div dangerouslySetInnerHTML={displayHTMLContent(items.filter(q => q.code === code)[0].content)} />
            )}
        </>
    );
};

export default WidgetContentByCode;