import { useEffect, useState } from 'react';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { defaultConfirmationMessage, IConfirmationMessage, } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification, } from '../../core/components/messaging/entities/Notification';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { ColumnType, IColumn, } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { WebFileInfo } from '../../core/types/web-file-info';
import { deEncodeData } from '../../core/utilities/FileHelper';
import { getPropertyValue } from '../../core/utilities/JsonHelper';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { defaultDcFileUpload, IDcFileRequestWithNavigationProperties, IDcFileUpload, } from '../entities/DcFileRequestWithNavigationProperties';
import { useGetDcFileDownload } from '../services/DcFileDownloadService';
import { useGetDcFilePreview } from '../services/DcFilePreviewService';
import { IDcFileUploadFilter, useGetDcFileUploadList, } from '../services/DcFileUploadListService';
import DcFilePreviewFormik from './DcFilePreviewFormik';
import DcFileUploadFormik from './DcFileUploadFormik';
import Filters from './Filters';

const DcFileUploadList: React.FunctionComponent<ICoreComponentInfo> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [dcFileUploads, setDcFileUploads] = useState<IDcFileUpload[]>([]);
    const [item, setItem] = useState<IDcFileUpload>(defaultDcFileUpload);
    const [previewFile, setPreviewFile] = useState<WebFileInfo | null>(null);
    const [hasHeader, setHasHeader] = useState<boolean>(false);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [currentDcFileUploadFilter, setCurrentDcFileUploadFilter] =
        useState<IDcFileUploadFilter | null>(null);
    const [confirmation] = useState<IConfirmationMessage>(
        defaultConfirmationMessage
    );
    const [notification, setNotification] =
        useState<INotification>(defaultNotification);
    const { service: dcFileUploadListService, setDcFileUploadFilter } =
        useGetDcFileUploadList();
    const { service: dcFileToDownloadService, setDcFileToDownload } =
        useGetDcFileDownload();
    const { service: dcFileToPreviewService, setDcFileToPreview } =
        useGetDcFilePreview();

    const onUpload = (i: IDcFileUpload): void => {
        setItem(i);
        setShowDownloadModal(true);
    };

    const onPreview = (i: IDcFileUpload): void => {
        setHasHeader(i.hasHeader);
        setDcFileToPreview(i.id);
    };

    const onPreviewCancel = () => {
        setShowPreviewModal(false);
    };

    const onDownload = (i: IDcFileUpload): void => {
        setDcFileToDownload(i.id);
    };

    const onDownloadSave = () => {
        setShowDownloadModal(false);
        refreshItems();
    };

    const onDownloadCancel = () => {
        setShowDownloadModal(false);
    };

    const getStatus = (status: number | undefined): string => {
        switch (status) {
            case 1:
                return 'Not Started';
            case 2:
                return 'Uploaded';
            case 3:
                return 'Error';
            case 4:
                return 'Requested';
            case 5:
                return 'Rejected';
            case 6:
                return 'Accepted';
            default:
                return '';
        }
    };

    const report = {
        title: 'Upload Agency Files', //TODO: Get this from ???
    };

    const actions: IColumnAction[] = [
        {
            icon: Icon.Upload,
            onClick: onUpload,
            helpText: 'Upload file', //TODO: need to determine when to not show this
        },
        {
            icon: Icon.Preview,
            onClick: onPreview,
            helpText: 'Preview file',
            condition: 'requestStatus',
            conditionValue: 1,
            notCondition: true,
        },
        {
            icon: Icon.Download,
            onClick: onDownload,
            helpText: 'Download file',
            condition: 'requestStatus',
            conditionValue: 1,
            notCondition: true,
        },
    ];

    const columns: IColumn[] = [
        {
            id: 'agencyCode',
            name: 'Agency',
            sort: true,
            type: ColumnType.String,
        },
        {
            id: 'fileTypeName',
            name: 'File Type',
            sort: true,
            type: ColumnType.String,
        },
        {
            id: 'uploadedFileName',
            name: 'Uploaded File Name',
            sort: true,
            type: ColumnType.String,
        },
        {
            id: 'uploadedRows',
            name: 'Uploaded Rows',
            sort: true,
            type: ColumnType.Integer,
            className: 'text-right',
        },
        {
            id: 'uploadedOn',
            name: 'Updated On',
            sort: true,
            type: ColumnType.String,
        },
        {
            id: 'uploadedBy',
            name: 'Updated By',
            sort: true,
            type: ColumnType.String,
        },
        {
            id: 'status',
            name: 'Status',
            sort: true,
            type: ColumnType.String,
        },
        {
            actions,
            id: 'Actions',
            name: '',
            sort: false,
            type: ColumnType.Actions,
        },
    ];

    const onDcUploadFilterChange = (
        dcFileUploadFilter: IDcFileUploadFilter
    ) => {
        setCurrentDcFileUploadFilter(dcFileUploadFilter);
        setDcFileUploadFilter(dcFileUploadFilter);
    };

    const refreshItems = () => {
        setDcFileUploadFilter(currentDcFileUploadFilter); //refresh the list
    };

    useEffect(() => {
        if (
            dcFileUploadListService.result !== undefined &&
            dcFileUploadListService.result.items
        ) {
            const list = dcFileUploadListService.result.items.map(
                (item: IDcFileRequestWithNavigationProperties) => {
                    let headers = getPropertyValue(
                        item?.dcFileType?.customProperty,
                        'headers',
                        ''
                    );

                    return {
                        id: item?.dcFileRequest?.id,
                        agencyCode: item?.dcAgency?.code,
                        agencyAzureDataSource: item?.dcAgency?.azureDataSource,
                        agencyAzureFolder: item?.dcAgency?.azureFolder,
                        azureFileUrl: item?.dcFileRequest?.azureFileUrl,
                        agencyName: item?.dcAgency?.name,
                        fileTypeCode: item?.dcFileType?.code,
                        fileTypeName: item?.dcFileType?.name,
                        term: item?.dcFileRequest?.term,
                        uploadedFileName: item?.dcFileRequest?.name,
                        uploadedRows: item?.dcFileRequest?.rowCount,
                        uploadedBy: item?.dcFileRequest?.uploadedBy,
                        uploadedOn: item?.dcFileRequest?.uploadedOn
                            ? new Date(
                                item.dcFileRequest.uploadedOn + 'z'
                            ).toLocaleString()
                            : '',
                        requestStatus: item?.dcFileRequest?.requestStatus,
                        status: getStatus(item?.dcFileRequest?.requestStatus),
                        fileTypeCategoryName: item?.dcFileTypeCategory?.name,
                        fileTypeGroupName: item?.dcFileTypeGroup?.name,
                        hasHeader: headers.trim().length > 0 ? true : false,
                    };
                }
            );

            setDcFileUploads(list);
            setLoading(false);
        }
    }, [dcFileUploadListService]);

    useEffect(() => {
        if (dcFileToDownloadService.result !== undefined) {
            const webFileInfo = dcFileToDownloadService.result as WebFileInfo;

            const blob = deEncodeData(webFileInfo);
            const element = document.createElement('a');
            element.href = URL.createObjectURL(blob);
            element.download = webFileInfo.name;

            element.click();
        }
    }, [dcFileToDownloadService]);

    useEffect(() => {
        if (dcFileToPreviewService.result !== undefined) {
            const webFileInfo = dcFileToPreviewService.result as WebFileInfo;

            setPreviewFile(webFileInfo);
            setShowPreviewModal(true);
        }
    }, [dcFileToPreviewService, setShowPreviewModal]);

    return (
        <>
            <div
                style={{
                    marginLeft: '15px',
                    marginRight: '15px',
                    marginTop: '15px',
                }}
            >
                <OERow>
                    <OECol lg={6} md={12} sm={12}>
                        <h4>{report.title}</h4>
                    </OECol>

                    <OECol lg={6} md={12} sm={12}>
                        <Filters
                            setDcFileUploadFilter={onDcUploadFilterChange}
                        />
                    </OECol>
                </OERow>

                <hr style={{ marginTop: '0px' }} />

                <OERow>
                    <OETable
                        loading={loading}
                        loadingMessage="Loading Files"
                        data={dcFileUploads}
                        columns={columns}
                        showPagination={(dcFileUploads?.length || 0) > 10}
                        defaultSort="name"
                        noDataMessage="No files exist"
                        defaultPageSize={25}
                    />
                </OERow>

                {showDownloadModal && (
                    <DcFileUploadFormik
                        id={item.id}
                        agencyName={item?.agencyName || ''}
                        agencyCode={item?.agencyCode || ''}
                        agencyAzureDataSource={
                            item?.agencyAzureDataSource || ''
                        }
                        agencyAzureFolder={item?.agencyAzureFolder || ''}
                        azureFileUrl={item?.azureFileUrl || ''}
                        fileTypeName={item?.fileTypeName || ''}
                        fileTypeCategoryName={item?.fileTypeCategoryName}
                        fileTypeGroupName={item?.fileTypeGroupName}
                        term={item?.term || ''}
                        accepts={'.csv'}
                        onCancel={onDownloadCancel}
                        onSave={onDownloadSave}
                    />
                )}

                {showPreviewModal && (
                    <DcFilePreviewFormik
                        webFileInfo={previewFile ?? undefined}
                        hasHeader={hasHeader}
                        onPreviewCancel={onPreviewCancel}
                    />
                )}

                <OEConfirmation {...confirmation} />

                <OENotification
                    setNotification={setNotification}
                    notification={notification}
                />
            </div>
        </>
    );
};

export default DcFileUploadList;
