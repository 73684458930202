import React, { useState } from 'react';
import OELink from '../../../core/components/general/OELink';
import { MenuTarget } from '../../entities/Menu';
import { ISiteMenu } from '../../entities/SiteMenu';
import '../../styles/SiteMenu.css';
import { saveRootMenu } from '../SiteMenu';

interface IComponentInfo {
    item: ISiteMenu;
    isActive?: boolean;
}

const Menu: React.FunctionComponent<IComponentInfo> = ({ item, isActive }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        saveRootMenu(item);

        if (item.children.length > 0) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <li>
            <OELink
                icon={item.icon}
                onClick={onClick}
                href={item.children.length > 0 ? undefined : item.path}
                className={`nav-link link-light px-3 ${isActive ? 'active' : ''} ${item.children.length > 0 ? (item.parentId > 0 ? 'subchildmenu' : 'childmenu') : ''}`}
                aria-current="page"
                target={item.target || MenuTarget.Self}
                aria-expanded={isOpen}
                aria-haspopup={item.children.length > 0 ? "true" : undefined}
                id={`link-${item.id}`}
            >
                {item.name}
            </OELink>
            {item.children.length > 0 && (
                <ul className={isOpen ? 'open' : ''}>
                    {item.children.map((menu, index) =>
                        <Menu item={menu} isActive={false} key={menu.id} />
                    )}
                </ul>
            )}
        </li>

    );
};

export default Menu;
