import React, { useEffect, useState } from 'react';
import AlertSubscriptionsList from '../alert-subsciptions/components/AlertSubscriptionsList';
import AlertUserMessageHistoryList from '../alerts/components/AlertUserMessageHistoryList';
import OEHeading from '../core/components/general/OEHeading';
import OETabs, { OETab } from '../core/components/tabs/OETabs';
import { Icon } from '../core/entities/Icon';
import { ICoreComponentInfo } from '../site/components/OECoreComponent';
import UserImageFormik from './components/profile/UserImageFormik';
import UserPermissions from './components/profile/UserPermissions';
import UserProfileFormik from './components/profile/UserProflileFormik';
import { defaultProfile, IProfile } from './entities/Profile';
import { defaultUser, IUser } from './entities/User';
import { useGetProfile } from './services/ProfileService';
import { useGetCurrentUser } from './services/UserService';

const UserProfile: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties, setRouteProperties }) => {
    const [activeTab, setActiveTab] = useState('profile');
    const { service: profileService } = useGetProfile();
    const { service: userService } = useGetCurrentUser();

    const [profile, setProfile] = useState<IProfile>(defaultProfile);
    const [currentUser, setCurrentUser] = useState<IUser>(defaultUser);

    const setTab = (event: any) => {
        setActiveTab(event);
    };

    useEffect(() => {
        if (profileService.result) {
            setProfile(profileService.result);
        }
    }, [profileService]);

    useEffect(() => {
        if (userService.result) {
            setCurrentUser(userService.result);
        }
    }, [userService]);

    const onSuccess = () => {
        window.location.reload();
    }

    return <div className="container">
        <OEHeading className="m-b-20 m-t-10" text="My Profile" icon={Icon.UserManagement} />
        {currentUser.id !== '' && profile.email !== '' && (
            <OETabs className="m-t-40" defaultTab={activeTab} onSelect={setTab}>
                <OETab title="Profile" eventKey="profile">
                    <UserProfileFormik profile={profile} onSuccess={onSuccess} />
                </OETab>
                <OETab title="My Profile Image" eventKey="image">
                    <UserImageFormik profile={profile} onSuccess={onSuccess} />
                </OETab>
                <OETab title="My Roles/Permissions" eventKey="permission">
                    <UserPermissions item={currentUser} />
                </OETab>
                <OETab title="My Notifications" eventKey="notifications">
                    <AlertSubscriptionsList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />
                </OETab>
                <OETab title="Message History" eventKey="messageHistory">
                    <AlertUserMessageHistoryList />
                </OETab>
            </OETabs>
        )}

    </div>;
};

export default UserProfile;