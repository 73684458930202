import React from 'react';
import { ICoreComponentInfo } from '../../../../site/components/OECoreComponent';
import MSEmbeddedTierOneReport from './MSEmbeddedTierOneReport';

const MSEmbeddedTierOne: React.FunctionComponent<ICoreComponentInfo> = () => {
    return (
        <MSEmbeddedTierOneReport />
    );
};

export default MSEmbeddedTierOne;
