import React, { useEffect, useState } from 'react';
import { IBreadcrumb } from './entities/Breadcrumb';
import OEBreadcrumbItem from './OEBreadcrumbItem';

interface IOEBreadcrumbInfo {
    breadcrumbs: IBreadcrumb[];
    navigateBreadcrumb?: (crumb: IBreadcrumb) => void;
    setBreadcrumbs?: (crumnbs: IBreadcrumb[]) => void;
    noLinks?: boolean;
    hide?: boolean;
    className?: string;
    isBlack?: boolean;
}

const OEBreadcrumb: React.FunctionComponent<IOEBreadcrumbInfo> = ({
    breadcrumbs,
    navigateBreadcrumb,
    noLinks,
    hide,
    className,
    setBreadcrumbs,
    isBlack = true
}: IOEBreadcrumbInfo) => {

    const [localBreadcrumbs, setLocalBreadcrumbs] = useState<IBreadcrumb[]>([]);

    useEffect(() => {
        setLocalBreadcrumbs([...breadcrumbs]);
    }, [breadcrumbs]);

    const [hideCrumbs] = useState<boolean>(hide || false);

    const onNavigate = (crumb: IBreadcrumb, index: number) => {
        if (crumb.onNavigate) {
            crumb.onNavigate();
        }

        if (crumb.url) {
            window.location.href = crumb.url;
        }

        else if (navigateBreadcrumb && !noLinks && index < localBreadcrumbs.length - 1) {
            navigateBreadcrumb?.(crumb);
            const newBreadcrumbs = localBreadcrumbs;
            newBreadcrumbs.splice(index + 1, localBreadcrumbs.length - index + 2);
            setLocalBreadcrumbs([...newBreadcrumbs]);
            setBreadcrumbs?.([...newBreadcrumbs]);
        }
    };

    return (
        <>
            {!hideCrumbs && (
                <ol className={`breadcrumb ${className || ''}`}>
                    {localBreadcrumbs.map((breadcrumb: IBreadcrumb, index: number) =>
                        <OEBreadcrumbItem
                            key={index}
                            index={index}
                            breadcrumb={breadcrumb}
                            length={localBreadcrumbs.length}
                            onNavigate={onNavigate}
                            isBlack={isBlack}
                        />
                    )}
                </ol>
            )}
        </>
    );
};

export default OEBreadcrumb;
