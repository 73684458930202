import React, { useEffect, useState } from 'react';
import { defaultBreadcrumb, IBreadcrumb } from '../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../core/components/breadcrumbs/OEBreadcrumb';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { defaultModule, getModuleList, IModule } from '../entities/Module';
import { defaultRoute, getRouteList, IRoute } from '../entities/Route';
import { useGetModules, useGetRoutes, usePutRoute } from '../services/RouteService';
import { ISelectedSiteAdminInfo } from '../SiteAdmin';
import ModulePermissions from './ModulePermissions';
import RouteFormik from './RouteFormik';
import RoutePermissions from './RoutePermissions';

enum ModalTypes {
    None = 1,
    Modules,
    RoutePermissions,
    ModulePermissions,
    Edit

}

const Routes: React.FunctionComponent<ISelectedSiteAdminInfo> = () => {

    const { service, doRefresh } = useGetRoutes();
    const { service: moduleService, setRouteId } = useGetModules();
    const { service: activeService, setRoute: setActiveServiceItem } = usePutRoute();

    const [items, setItems] = useState<IRoute[]>([]);
    const [item, setItem] = useState<IRoute>(defaultRoute);
    const [modules, setModules] = useState<IModule[]>([]);
    const [module, setModule] = useState<IModule>(defaultModule);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Routes' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (service.result) {
            setItems(getRouteList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (moduleService.result) {
            setModules(getModuleList(moduleService.result));
        }
    }, [moduleService]);


    useEffect(() => {
        if (activeService.isFinished && !activeService.isSuccess) {
            setNotification({ message: 'An error occurred updating the active status', type: 'error' })
        }
    }, [activeService]);

    const onSave = () => {
        setShowModal(ModalTypes.None);
        doRefresh();
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onCancelModule = () => {
        setShowModal(ModalTypes.Modules);
    };

    const onRoutePermissions = (i: IRoute) => {
        setItem(i);
        setShowModal(ModalTypes.RoutePermissions);
    };

    const onEdit = (i: IRoute) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onModulePermissions = (i: IModule) => {
        setModule(i);
        setShowModal(ModalTypes.ModulePermissions);
    };

    const onViewModules = (i: IRoute) => {
        setBreadcrumbs([...rootBreadcrumbs, { ...defaultBreadcrumb, id: 1, name: i.name }]);
        setRouteId(i.id);
        setShowModal(ModalTypes.Modules);
    };

    const onUpdateActive = (i: IRoute) => {
        i.isActive = !i.isActive;
        setActiveServiceItem({ ...i });
    };

    const onConfirmActive = (i: IRoute) => {
        if (i.path === '/siteadmin') {
            setConfirmation({
                ...defaultConfirmationMessage, setConfirmation, show: true, title: "Route Cannot be changed",
                message: `This route can not be changed to inactive.`, onCancel: onCancel
            });
        }
        else {
            setConfirmation({
                ...defaultConfirmationMessage, setConfirmation, item: i, show: true, title: "Update Active Status?",
                message: `Are you sure you want change the active status for the route <b>${i.name}</b>?`,
                onOk: onUpdateActive, onCancel: onCancel
            });
        }
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Permissions, onClick: onRoutePermissions, helpText: 'Update Permissions' },
        { icon: Icon.Modules, onClick: onViewModules, helpText: 'View Modules' },
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
    ];

    const activeActions: IColumnAction[] = [
        { icon: Icon.CheckYes, condition: 'isActive', onClick: onConfirmActive, helpText: `Deactivate` },
        { icon: Icon.CheckNo, condition: 'isActive', notCondition: true, onClick: onConfirmActive, helpText: `Activate` },
    ];

    const columns: IColumn[] = [
        { actions, id: '', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'name', name: 'Name', sort: true, type: ColumnType.String },
        { id: 'id', name: 'ID', sort: true, type: ColumnType.ID },
        { id: 'path', name: 'Path', sort: true, type: ColumnType.NavigationLink },
        { id: 'component', name: 'Component', sort: true, type: ColumnType.String },
        { actions: activeActions, id: 'isActive', name: 'Active', width: '20px', sort: true, type: ColumnType.Actions, className: 'text-center' },
    ];

    const moduleActions: IColumnAction[] = [
        { icon: Icon.Permissions, onClick: onModulePermissions, helpText: 'Update Permissions' },
    ];

    const moduleColumns: IColumn[] = [
        { actions: moduleActions, id: '', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'name', name: 'Name', sort: true, type: ColumnType.String },
        { id: 'component', name: 'Route:Component', sort: true, type: ColumnType.String },
    ];

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        setBreadcrumbs({ ...rootBreadcrumbs });
        setShowModal(ModalTypes.None);
    };

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OENotification setNotification={setNotification} notification={notification} />
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />
            {(showModal === ModalTypes.RoutePermissions || showModal === ModalTypes.None) && (
                <OETable
                    loading={service.isInProgress}
                    loadingMessage="Loading Routes"
                    data={items}
                    columns={columns}
                    showPagination={false}
                    defaultSort="name"
                />
            )}

            {(showModal === ModalTypes.ModulePermissions || showModal === ModalTypes.Modules) && (
                <OETable
                    loading={moduleService.isInProgress}
                    loadingMessage="Loading Modules"
                    data={modules}
                    columns={moduleColumns}
                    showPagination={false}
                    defaultSort="name"
                />
            )}

            {showModal === ModalTypes.RoutePermissions && (
                <RoutePermissions onCancel={onCancel} item={item} />
            )}

            {showModal === ModalTypes.ModulePermissions && (
                <ModulePermissions onCancel={onCancelModule} item={module} />
            )}

            {showModal === ModalTypes.Edit && (
                <RouteFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}
        </>
    );
};

export default Routes;