import React, { useRef, useEffect } from 'react';

const SkipControl = ({ targetId = 'main-content' }) => {
    const skipRef = useRef<HTMLButtonElement>(null);

    const handleSkip = () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

            const firstFocusableElement = targetElement.querySelector(
                'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
            ) as HTMLElement;

            if (firstFocusableElement) {
                firstFocusableElement.focus();
            } else {
                console.warn(`No focusable elements found inside ${targetId}`);
            }

            if (skipRef.current) {
                skipRef.current.style.opacity = '0';
                skipRef.current.style.pointerEvents = 'none';
            }
        }
    };

    const handleFocus = () => {
        if (skipRef.current) {
            skipRef.current.style.opacity = '1';
            skipRef.current.style.pointerEvents = 'auto';
        }
    };

    const handleBlur = () => {
        if (skipRef.current) {
            skipRef.current.style.opacity = '0';
            skipRef.current.style.pointerEvents = 'none';
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleSkip();
        }
    };

    useEffect(() => {
        const skipButton = skipRef.current;
        if (skipButton) {
            skipButton.addEventListener('focus', handleFocus);
            skipButton.addEventListener('blur', handleBlur);
        }

        return () => {
            if (skipButton) {
                skipButton.removeEventListener('focus', handleFocus);
                skipButton.removeEventListener('blur', handleBlur);
            }
        };
    }, []);

    return (
        <div>
            <button
                tabIndex={0}
                ref={skipRef}
                onClick={handleSkip}
                onKeyDown={handleKeyDown}
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    zIndex: 1000,
                    opacity: '0',
                    pointerEvents: 'none',
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ccc',
                    transition: 'opacity 0.3s ease',
                }}
            >
                Skip to Content
            </button>
        </div>
    );
};

export default SkipControl;
