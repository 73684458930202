import React, { useEffect, useState } from 'react';
import OEFilter from '../../../core/components/filter/OEFilter';
import { IFilter } from '../../../core/components/filter/entities/Filter';
import OEHeading from '../../../core/components/general/OEHeading';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OENotification from '../../../core/components/messaging/OENotification';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../../core/components/messaging/entities/Notification';
import OESort from '../../../core/components/sort/OESort';
import { ISortData } from '../../../core/components/sort/entities/SortData';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn, ModifiedColumns } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { Icon } from '../../../core/entities/Icon';
import { processPostRequest } from '../../../core/services/PostRequest';
import { ICoreComponentInfo } from '../../../site/components/OECoreComponent';
import { defaultReference, IReference } from '../../entities/Reference';
import { defaultPaginationFilter, IUserPaginationFilter } from '../../entities/User';
import { getReferenceList, useDeleteReference, useGetReference, usePutReference } from '../../services/ReferenceService';
import ReferenceFormik from './ReferenceFormik';

enum ModalTypes {
    None = 1,
    Edit,
    Permissions,
    Sort
}

const ReferenceTable: React.FunctionComponent<ICoreComponentInfo> = () => {

    const { service, setParams } = useGetReference();
    const { service: deleteService, setDeleteId } = useDeleteReference();
    const { service: putService, setReference } = usePutReference();

    const [items, setItems] = useState<IReference[]>([]);
    const [filterList, setFilterList] = useState<IReference[]>([]);
    const [item, setItem] = useState<IReference>(defaultReference);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [paginationParams, setPaginationParams] = useState<IUserPaginationFilter>(defaultPaginationFilter);
    const [sortList, setSortList] = useState<ISortData[]>([]);
    const [saveList, setSaveList] = useState<IReference[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service && service.result) {
            setItems(getReferenceList(service.result));
        }
    }, [service]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: deleteService,
            successAction: refreshItems,
            successMessage: 'Reference Deleted Successfully',
            errorMessage: 'Error occurred deleting Reference',
        });
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        if (putService.isFinished) {
            if (putService.isSuccess) {
                saveList.shift();
                setSaveList([...saveList]);
                if (saveList.length === 0) {
                    refreshItems();
                }
            } else {
                setErrors([putService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [putService]);

    useEffect(() => {
        if (saveList.length > 0) {
            setReference(saveList[0]);
        } else {
            setShowModal(ModalTypes.None);
        }
        // eslint-disable-next-line
    }, [saveList]);

    const refreshItems = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onEdit = (i: IReference) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
        setParams({ ...paginationParams });
    };

    const onConfirmDelete = (i: IReference) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Delete Reference?',
            message: `Are you sure you want to delete the Reference?`,
            onOk: onDelete,
            onCancel: onCancel,
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { id: 'sortOrder', name: 'Sort', sort: true, type: ColumnType.Integer },
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'title', idNewLine: 'definition', name: 'Title/Description', sort: true, type: ColumnType.Link, onClick: onEdit },
        { id: 'url', name: 'URL', sort: true, type: ColumnType.NavigationLink },
        ...ModifiedColumns,
    ];

    const onAdd = () => {
        setItem(defaultReference);
        setShowModal(ModalTypes.Edit);
    };

    const filter: IFilter = {
        name: 'referenceAdmin',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['title'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by Title' },
        ],
    };

    const onSort = () => {
        setErrors([]);
        if (filterList) {
            const list: ISortData[] = [];
            for (const item of filterList) {
                list.push({ id: item.id, name: item.title, sortOrder: item.sortOrder });
            }
            setSortList(list);
            setShowModal(ModalTypes.Sort);
        }
    };

    const onSortOK = (d: ISortData[]) => {
        const l: IReference[] = [];
        for (const m of d) {
            const m2 = items.filter(q => q.id === m.id)[0];
            if (m2.sortOrder !== m.sortOrder) {
                l.push({ ...m2, sortOrder: m.sortOrder });
            }
        }
        setSaveList(l);
        if (l.length === 0) {
            setShowModal(ModalTypes.None);
        }
    };

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Reference Administration" icon={Icon.UserManagement} />
            <OENotification setNotification={setNotification} notification={notification} />
            <OEFilter singleLine={true} refresh={() => setParams({ ...paginationParams })} onFilter={(value) => {
                setPaginationParams({ ...paginationParams, filter: value });
                setParams({ ...paginationParams, filter: value });
            }} className="m-t-0" items={items} filter={filter} setFilterList={setFilterList} />
            <OETable
                data={filterList}
                columns={columns}
                showPagination={filterList.length > 10}
                showExport={true}
                loading={service.isInProgress}
                loadingMessage="Loading Reference"
                defaultSort="textCol"
                defaultPageSize={25}
                noDataMessage={`No reference exists.`}
                actions={[
                    { icon: Icon.Add, text: 'Add New Term', action: onAdd },
                    { hidden: filterList.length < 2, icon: Icon.Sort, text: 'Sort Items', action: onSort },
                ]}
            />
            {showModal === ModalTypes.Edit && (
                <ReferenceFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}
            {showModal === ModalTypes.Sort && (
                <OESort show errors={errors} items={sortList} title={`Sort Items`} onOk={onSortOK} onCancel={onCancel} />
            )}
            <OEConfirmation {...confirmation} />
        </div>
    );
};

export default ReferenceTable;