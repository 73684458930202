import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import { OERow } from '../../../core/components/grid/OERow';
import { Icon } from '../../../core/entities/Icon';
import { parseQueryString } from '../../../core/utilities/URL';
import { ICoreComponentInfo } from '../../../site/components/OECoreComponent';
import { getReportGroupList, IReportGroup } from '../../entities/ReportGroup';
import { defaultReportPage, IReportPage } from '../../entities/ReportPage';
import { useGetReportGroupsByPage } from '../../services/ReportGroupService';
import { useGetReportPage } from '../../services/ReportPageService';
import Group from './Group';


const ReportList: React.FunctionComponent<ICoreComponentInfo> = () => {
    const params: any = parseQueryString();
    const { service, setItemId: setReportPageId } = useGetReportGroupsByPage();
    const { service: pageService, setPageId } = useGetReportPage('');

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [items, setItems] = useState<IReportGroup[]>([]);
    const [pageId] = useState(params['id']);


    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
    }, [pageService]);

    useEffect(() => {
        if (service.result) {
            setItems(getReportGroupList(service.result));
        }
    }, [service]);

    useEffect(() => {
        setPageId(pageId);
        setReportPageId(pageId);
        // eslint-disable-next-line
    }, [pageId]);


    return (
        <div className="container">
            <OEHeading className="m-t-10" text={page.name} icon={Icon.ReportAdministration} />
            <OERow>
                {items.filter(q => q.isActive).map((group, index) =>
                    <Group key={index} index={index} item={group} />
                )}
            </OERow>
        </div>
    );
};

export default ReportList;