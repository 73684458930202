import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { IRoute } from '../entities/Route';
import { useCreateRoute, usePutRoute } from '../services/RouteService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IRoute> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Path" name="path" value={values.path} disabled={true}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Component" name="component" value={values.component}
                errors={errors} touched={touched} columns={labelColumns} disabled={true}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IRoute>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
    path: Yup.string().required('Path is required').min(2).max(250).nullable(),
    component: Yup.string().required('Component is required').min(1).max(250).nullable(),
});

interface IFormikInfo {
    item: IRoute;
    onCancel: () => void;
    onSave: () => void;
}

const RouteFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {
    const { service, setRoute } = usePutRoute();
    const { service: createService, setRoute: setCreateRoute } = useCreateRoute();

    return (
        <OEFormikModal
            item={item}
            labelColumns={labelColumns}
            title={`${item.id > 0 ? `Edit Route: ${item.name}` : `Add New Route`}`}
            progressMessage={ProgressMessages.Route}
            successMessage={SuccessMessages.Route}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id > 0 ? setRoute : setCreateRoute}
            service={item.id > 0 ? service : createService}
        />
    );
};

export default RouteFormik;
