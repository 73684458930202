import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import OEMessage from '../../../../core/components/messaging/OEMessage';
import OENotification from '../../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../../../core/utilities/String';
import { parseQueryString } from '../../../../core/utilities/URL';
import { IReport, canDebugReport, defaultReport } from '../../../../reporting/entities/Report';
import { ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { ReportGroupType } from '../../../../reporting/entities/ReportGroup';
import { ProcessingPage, logProcessingPage } from '../../../../reporting/entities/ReportProcessing';
import { useGetReport } from '../../../../reporting/services/ReportService';
import { IMicroStrategyFilter } from '../../../entities/MicroStrategyFilters';
import { ICubeAttribute } from '../../../entities/api/CubeResponse';
import Cube from '../../common/Cube';
import DebugMessage from '../../common/DebugMessage';
import PageFilters from '../../common/PageFilters';
import Report from '../../common/Report';
import TokenValidation from '../../common/TokenValidation';
import { IBreadcrumb } from '../../../../core/components/breadcrumbs/entities/Breadcrumb';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    isPublic?: boolean;
    breadcrumbs?: IBreadcrumb[];
    setBreadcrumbs?: Dispatch<SetStateAction<IBreadcrumb[]>>;
}

const MSEmbeddedPageReport: React.FunctionComponent<IComponentInfo> = ({ isPublic }) => {
    const params: any = parseQueryString();
    const [debug] = useState<boolean>(canDebugReport());
    // const reportId = params['id'] || '';
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: reportService, setItemId: setReportId } = useGetReport();

    const [validToken, setValidToken] = useState<boolean>(false);
    const [report, setReport] = useState<IReport>(defaultReport);
    const [footer, setFooter] = useState<string>('');
    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);

    const [cubeId, setCubeId] = useState<string>('');
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);

    const [reportHeight, setReportHeight] = useState<number>(0);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);
    const [error, setError] = useState<string>('');
    const [debugMessage, setDebugMessage] = useState<string>('');

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.PageStart:
                setProcessingStep(ProcessingPage.PageLoad);
                break;

            case ProcessingPage.PageLoad:
                setProcessingStep(ProcessingPage.ReportLoad);
                break;

            case ProcessingPage.ReportLoad:
                setReportId(params.id);
                break;

            case ProcessingPage.ReportLoaded:
                updateHeight();
                break;

            case ProcessingPage.PageFiltersLoaded:
                setProcessingStep(ProcessingPage.Complete);
                break;

            case ProcessingPage.UpdateDossierFilters:
                break;

            case ProcessingPage.Complete:
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        window.addEventListener("resize", windowResized);
        return () => {
            window.removeEventListener("resize", windowResized);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.PageStart);
        }
        // eslint-disable-next-line
    }, [validToken]);

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    const windowResized = () => {
        updateHeight();
    }

    const updateHeight = () => {
        if (reportRef.current) {
            const h: number =
                reportRef.current.offsetTop
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 0) + 45;
            setReportHeight(h);
        }
    }

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
        console.log(debugMessage);
    }

    const setCubeError = (e: string) => {
        setError(`Cube Load Error: ${e}`);
        setProcessingStep(ProcessingPage.FatalError);
    }

    return (
        <>
            <OENotification setNotification={setNotification} notification={notiication} />
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            <DebugMessage debug={debug}>Page: {processingStep}</DebugMessage>
            <div className="report-embedded m-b-0 three-tier">
                <div className="content">
                    <p className="title m-t-10 m-b-5 m-l-10">{`${report.title}`} </p>
                    <Cube
                        validToken={validToken}
                        setCube={setCube}
                        report={report}
                        setNotification={setNotification}
                        cubeId={cubeId}
                        setError={setCubeError}
                        setDebugMessage={setDebugMessage}
                    />

                    <PageFilters
                        setNotification={setNotification}
                        pageProcessingStep={processingStep}
                        report={report}
                        setPageProcessingStep={setProcessingStep}
                        setPageFilters={setPageFilters}
                        cubeAttributes={cubeAttributes}
                        setDebugMessage={setDebugMessage}
                        hidden={isPublic}
                    />

                    {processingStep === ProcessingPage.FatalError && (
                        <OEMessage
                            className="report-errors"
                            hideDismissable={true}
                            message={error}
                            type={MessageType.Error}
                        />
                    )}

                    {processingStep !== ProcessingPage.FatalError && (
                        <div ref={reportRef} className="report">
                            <Report
                                reportId={report.id}
                                setNotification={setNotification}
                                pageFilters={pageFilters}
                                pageProcessingStep={processingStep}
                                divId={`report0`}
                                configurations={{ ...defaultReportConfigurations, reportConfiguration: report.configuration }}
                                reportHeight={reportHeight}
                                groupType={ReportGroupType.Report}
                                multipleReports={false}
                                isPublic={isPublic}
                            />

                            {footer && (
                                <div ref={footerRef} className="footer" dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default MSEmbeddedPageReport;
