import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { getCookie, reactLogout } from '../../core/services/Authentication';
import { useLogout } from '../../security/services/SecurityService';
import { getSiteSetting, ISiteSettings, SiteSetting } from '../entities/SiteSettings';
declare global { var msLogout: any; }

interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
}

export function SessionController({ settings, logout, children }: { settings: ISiteSettings, logout: boolean, children: ReactNode }) {
    const { service: logoutService, logout: processLogout } = useLogout();

    const timerIdRef = useRef<number>(0);
    const [timer, setTimer] = useState<number>(getSiteSetting(SiteSetting.SessionTimeout) * 60);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [sessionTimeout, setSessionTimeout] = useState<number>(0);
    const [sessionTimer, setSessionTimer] = useState<ISessionTimer>({ seconds: -1, isActive: false, initialized: false, });
    const [browserCloseDelay, setBrowserCloseDelay] = useState<number>(0);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [lastVisibilityChange, setLastVisibilityChange] = useState<Date | null>(null);

    const resetTimer = useCallback(() => {
        if (sessionTimeout > 0 && sessionTimer.isActive) {
            const timeout: number = sessionTimeout * 60;
            var expires = new Date(Date.now() + timeout * 1000).toUTCString();
            document.cookie = `XSRF-TOKEN=${getCookie('XSRF-TOKEN')}; expires=${expires}; path=/;`;
            setTimer(timeout);
        }
    }, [sessionTimeout, sessionTimer]);

    useEffect(() => {
        return () => {
            window.clearInterval(timerIdRef.current);
            timerIdRef.current = 0;
        }
    }, [])

    const onLogout = useCallback(() => {
        setSessionTimer({ ...sessionTimer, isActive: false });
        notification.message === '' && setNotification({ message: SuccessMessages.Logout, type: 'success' });
        setConfirmation({ ...defaultConfirmationMessage });
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && getSiteSetting(SiteSetting.TranslateLanguage) === "es") {
            googleTranslateDropdown.value = 'en';
            googleTranslateDropdown.dispatchEvent(new Event('change'));
        }
        msLogout(getSiteSetting(SiteSetting.MSPath), getSiteSetting(SiteSetting.MSAuthenticationURL), processLogout);
    }, [sessionTimer, notification.message, processLogout]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === "hidden") {
            setLastVisibilityChange(new Date());
        } else {
            if (lastVisibilityChange) {
                const now = new Date();
                if (Math.floor((now.getTime() - lastVisibilityChange.getTime()) / 1000) >= sessionTimeout * 60) {
                    onLogout();
                }
            }
        }
    }, [lastVisibilityChange, sessionTimeout, onLogout])

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'scroll', 'click'];
        const handleWindowEvents = () => {
            resetTimer();
        };

        // listen for specific window events to ensure user is still active
        events.forEach(event =>
            window.addEventListener(event, handleWindowEvents)
        );
        document.addEventListener("visibilitychange", handleVisibilityChange);

        handleWindowEvents();

        //cleanup function
        return () => {
            events.forEach(event =>
                window.removeEventListener(event, handleWindowEvents)
            );
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [resetTimer, handleVisibilityChange]);

    const startTimer = () => {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = window.setInterval(() => setTimer((timer) => timer - 1), 1000);
    }

    useEffect(() => {
        const timeout: number = getSiteSetting(SiteSetting.SessionTimeout);
        if (timeout > 0) {
            setSessionTimeout(timeout);
            setBrowserCloseDelay(getSiteSetting(SiteSetting.BrowserCloseDelay));
            setSessionTimer({
                seconds: sessionTimeout * 60,
                isActive: true,
                initialized: false,
            });
        }
        startTimer();
    }, [settings, sessionTimeout]);

    useEffect(() => {
        if (logoutService.result !== undefined) {
            reactLogout();
        }
        startTimer();
        // eslint-disable-next-line
    }, [logoutService]);

    useEffect(() => {
        if (sessionTimer.seconds > 0) {
            if (timer < 0) {
                onLogout();
            }
            else if (timer <= 300) {
                setSessionTimer({ ...sessionTimer, isActive: false });
                setConfirmation({
                    ...defaultConfirmationMessage,
                    setConfirmation, show: true,
                    title: 'Session About to Expire',
                    message: `<p><b>Your session will end in  ${timer > 0 ? timer : 0} seconds due to inactivity</b></p><p>As a security precaution, if there is no additional activity in your online session,the session will end and you will be automatically logged out</p><p>If you are still working in your online session, choose OK to continue.</p>`,
                    onOk: extendSession,
                });
            }
            if (!sessionTimer.initialized) {
                window.onbeforeunload = function () {
                    var expires = new Date(Date.now() + browserCloseDelay * 1000).toUTCString();
                    document.cookie = `XSRF-TOKEN=${getCookie('XSRF-TOKEN')}; expires=${expires}; path=/;`;
                };
                setSessionTimer({ ...sessionTimer, initialized: true });
            }
        }
        // eslint-disable-next-line
    }, [timer]);


    useEffect(() => {
        logout && onLogout();
        // eslint-disable-next-line
    }, [logout]);

    useEffect(() => {
        if (logoutService.result !== undefined) {
            reactLogout();
        }
        startTimer();
        // eslint-disable-next-line
    }, [logoutService]);

    const extendSession = (s?: boolean) => {
        !s && setConfirmation({ ...defaultConfirmationMessage });
        setSessionTimer({ ...sessionTimer, isActive: true });
        resetTimer();
    };

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OENotification setNotification={setNotification} notification={notification} />
            {children}
        </>
    );
};
