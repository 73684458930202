import React, { useEffect, useState } from 'react';
import { INotification } from '../../core/components/messaging/entities/Notification';
import OESpinner from '../../core/components/messaging/OESpinner';
import PageContent from '../components/PageContent';
import WidgetFormik from '../components/WidgetFormik';
import { defaultWidget, getWidgetList, IWidget } from '../entities/Widget';
import { getWidgetGroupList } from '../entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../services/WidgetService';
import WidgetContentByCode from './WidgetContentByCode';

enum ModalTypes {
    None = 1,
    Edit = 2,
}

interface IComponentInfo {
    setNotification: (i: INotification) => void;
}

const PublicHomePageAdmin: React.FunctionComponent<IComponentInfo> = ({ setNotification }) => {
    const groupCode: string = 'HOMEPAGE-PUBLIC';
    const { service: groupService } = useGetWidgetGroupByCode(groupCode);
    const { service, setGroupId: setServiceGroupId } = useGetWidgetsByGroupId();

    const [groupId, setGroupId] = useState<number>(-1);
    const [item, setItem] = useState<IWidget>({ ...defaultWidget });
    const [items, setItems] = useState<IWidget[]>([]);
    const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);

    useEffect(() => {
        setServiceGroupId(groupId);
        // eslint-disable-next-line
    }, [groupId]);

    const onEdit = (i: IWidget) => {
        setItem(i);
        setModalType(ModalTypes.Edit);
    };

    const onSave = () => {
        setModalType(ModalTypes.None);
        setServiceGroupId(groupId);
    };

    const onCancel = () => {
        setModalType(ModalTypes.None);
    };

    return (
        <div className="container">
            <OESpinner message="Loading Content" hidden={!service.isInProgress} />

            {modalType === ModalTypes.Edit && (
                <WidgetFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}

            {modalType === ModalTypes.None && items.length > 0 && (
                <>
                    <PageContent item={items.filter(q => q.code === 'CONTENT-PUBLIC')[0]} onEdit={onEdit}>
                        <WidgetContentByCode items={items} code='CONTENT-PUBLIC' />
                    </PageContent>

                    <div className="row">
                        <div className="col-8 m-t-40">
                            <PageContent item={items.filter(q => q.code === 'FOOTER1-PUBLIC')[0]} onEdit={onEdit}>
                                <WidgetContentByCode items={items} code='FOOTER1-PUBLIC' />
                            </PageContent>
                        </div>
                        <div className="col-md-4 m-t-40">
                            <PageContent item={items.filter(q => q.code === 'FOOTER2-PUBLIC')[0]} onEdit={onEdit}>
                                <WidgetContentByCode items={items} code='FOOTER2-PUBLIC' />
                            </PageContent>
                        </div>
                    </div>
                </>
            )}

        </div>
    );


};

export default PublicHomePageAdmin;