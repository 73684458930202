import React, { useEffect } from 'react';
import { reactLogout } from '../../core/services/Authentication';
import { SiteSetting, getSiteSetting } from '../entities/SiteSettings';
import { ICoreComponentInfo } from './OECoreComponent';
declare global { var msLogout: any; }

const Logout: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties }) => {

    const processLogout = () => {
        reactLogout();
    }
    useEffect(() => {
        msLogout(getSiteSetting(SiteSetting.MSPath), getSiteSetting(SiteSetting.MSAuthenticationURL), processLogout);
        // eslint-disable-next-line
    }, []);

    return (
        <></>
    );

};

export default Logout;
