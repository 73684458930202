import React from 'react';
import OEHeading from '../core/components/general/OEHeading';
import { ICoreComponentInfo } from './components/OECoreComponent';

const PageNotFound: React.FunctionComponent<ICoreComponentInfo> = () => {
    return (
        <div className="container m-t-40">
            <OEHeading size={1} text="Page Not Found" />
            <p>
                You've found a page that has either been deleted or moved or you do not have access to.
            </p>
            <p>
                Please use the site navigation to find what you're looking for or contact your administrator for further assistance.
            </p>
        </div>
    );
};

export default PageNotFound;