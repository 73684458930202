import { IBreadcrumb } from "../../core/components/breadcrumbs/entities/Breadcrumb";
import { defaultUser, IUser } from "../../user/entities/User";
import { defaultSiteSettings, ISiteSettings } from "./SiteSettings";

export interface ICoreRouteProperties {
    user: IUser;
    settings: ISiteSettings;
    academicYearID: string;
    academicYear: string;
    maintenance: boolean;
    breadcrumbs: IBreadcrumb[];
    setPageBreadcrumbs?: (p: string, i: IBreadcrumb[]) => void;
    corePageName: string;
    pageName: string;
    setPageName?: (i: string) => void;
}

export const defaultCoreRouteProperties: ICoreRouteProperties = {
    user: defaultUser, settings: defaultSiteSettings, academicYearID: '', academicYear: '', maintenance: false,
    breadcrumbs: [], pageName: '', corePageName: ''
};

