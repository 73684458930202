import React, { Fragment, useEffect, useRef, useState } from 'react';
import OEMessage from '../../core/components/messaging/OEMessage';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../core/utilities/String';
import Cube from '../../report-microstrategy/components/common/Cube';
import PageFilters from '../../report-microstrategy/components/common/PageFilters';
import Report from '../../report-microstrategy/components/common/Report';
import TokenValidation from '../../report-microstrategy/components/common/TokenValidation';
import { IDossierSettings, defaultDossierSettings } from '../../report-microstrategy/entities/MicroStrategyDossier';
import { IMSFilter, IMicroStrategyFilter, defaultMSFilter } from '../../report-microstrategy/entities/MicroStrategyFilters';
import { getReportBaseFilterFromJSON } from '../../report-microstrategy/entities/ReportFilter';
import { ICubeAttribute } from '../../report-microstrategy/entities/api/CubeResponse';
import ReportSectionLabel from '../../reporting/components/admin-reports/ReportSectionLabel';
import { IReport, canDebugReport, defaultReport } from '../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage, updateProcessingStep } from '../../reporting/entities/ReportProcessing';
import { useGetReportPublicPage } from '../../reporting/services/ReportPageService';
import { useGetReport } from '../../reporting/services/ReportService';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import SectionGroup from './SectionGroup';
import SectionItem from './SectionItem';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo extends ICoreComponentInfo {
    pageId: string;
}

const MSEmbeddedPublic: React.FunctionComponent<IComponentInfo> = ({ pageId }) => {
    const [debug] = useState<boolean>(canDebugReport());
    const headerRef = useRef<any>();
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: pageService, setPageId } = useGetReportPublicPage(''); // Step 4 - get the page information
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);

    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [section, setSection] = useState<IReportGroup>(defaultReportGroup);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);
    const [filters, setFilters] = useState<IMSFilter[]>([]);
    const [report, setReport] = useState<IReport>(defaultReport);
    const [configurations, setConfigurations] = useState<IReportConfigurations>(defaultReportConfigurations);
    const [validToken, setValidToken] = useState<boolean>(false);

    const [error, setError] = useState<string>('');
    const [cubeId, setCubeId] = useState<string>('');
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [debugMessage, setDebugMessage] = useState<string>('');

    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [navHeight, setNavHeight] = useState<number>(0);

    useEffect(() => {
        window.onresize = windowResized;
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.PageStart:
                setProcessingStep(ProcessingPage.PageLoad);
                break;

            case ProcessingPage.PageLoad:
                setPageId(pageId);
                break;

            case ProcessingPage.PageLoaded:
                try {
                    setProcessingStep(ProcessingPage.SectionLoad);
                }
                catch {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`Cube could not be setup based on the first report for this page.`);
                }
                break;

            case ProcessingPage.SectionLoad:
                setSection(page.groups[0].groups[0]);
                break;

            case ProcessingPage.SectionLoaded:
                setProcessingStep(ProcessingPage.GroupLoadInitial);
                break;

            case ProcessingPage.GroupLoadInitial:
                setGroup(section.groups.filter(q => q.isActive)[0]);
                break;

            case ProcessingPage.GroupLoad:
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                setProcessingStep(ProcessingPage.ReportLoad);
                break;

            case ProcessingPage.PageFiltersLoaded:
                setProcessingStep(ProcessingPage.Complete);
                break;


            case ProcessingPage.ReportLoad:
                if (group.reports.length === 0) {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`There are no reports setup for the report group: <b><i> ${group.title} </b></i> in the section <b><i> ${section.title}</b></i>`);
                } else {
                    if (cubeAttributes.length === 0) {
                        setGetReportId(group.reports[0].reportId);
                    }
                    else {
                        setProcessingStep(ProcessingPage.Complete);
                    }
                }
                break;

            case ProcessingPage.ReportLoaded:
                break;

            case ProcessingPage.Complete:
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);


    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            updateProcessingStep(ProcessingPage.PageLoaded, setProcessingStep);
            setConfigurations({ ...configurations, pageConfiguration: page.configuration });
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (section.id !== '') {
            //            updateProcessingStep(ReportProcessingStep.Finished ? ReportProcessingStep.LoadSection : ReportProcessingStep.SectionLoaded, setProcessingStep);
            updateProcessingStep(ProcessingPage.SectionLoaded, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (group.id !== '') {
            setProcessingStep(ProcessingPage.GroupLoadInitial);
            setConfigurations({ ...configurations, groupConfiguration: group.configuration });
        }
        // eslint-disable-next-line
    }, [group]);

    const windowResized = () => {
        updateHeight();
    }

    const loadGroup = () => {

        let nf: IMSFilter[] = filters.filter(q => q.level < 3);
        if (group.reports.length > 0) {
            debug && console.log(getReportBaseFilterFromJSON(group.reports[0].filters).filter(q => q.dossier));
            for (const i of getReportBaseFilterFromJSON(group.reports[0].filters).filter(q => q.dossier)) {
                nf.push({ ...defaultMSFilter, selectAllText: i.selectAllText, showSelectAll: i.showSelectAll, name: i.name, label: i.label, level: 3, dossier: true });
            }
            debug && console.log('selected report filters', group.reports[0].filters);
            debug && console.log('new filters', nf);
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, group.configuration));
        }
        setFilters(nf);
        setPageSettings({
            ...defaultDossierSettings, hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, page.configuration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, section.configuration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, group.configuration)
        });
        setProcessingStep(ProcessingPage.GroupLoaded);
    }

    const updateHeight = () => {
        if (headerRef.current) {
            const h: number =
                headerRef.current.getBoundingClientRect().height
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 16)
                + headerRef.current.offsetTop + 55;

            setReportHeight(h);
            setNavHeight(headerRef.current.offsetTop + 20);
        }
    }

    const onChangeSection = (i: IReportGroup) => {
        setReportHeight(0);
        setSection(i);
    }

    const onChangeGroup = (i: IReportGroup) => {
        setReportHeight(0);
        setGroup(i);
    }

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
        console.log(debugMessage);
    }


    const setCubeError = (e: string) => {
        setError(`Cube Load Error: ${e}`);
        setProcessingStep(ProcessingPage.FatalError);
    }

    return (
        <div className="report-embedded m-b-0">
            <OENotification setNotification={setNotification} notification={notiication} />
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            {debug && (
                <h5 className="text-success" style={{ position: 'absolute', top: '26px', left: '340px' }} >Page: {processingStep}</h5>
            )}

            <Cube
                validToken={validToken}
                setCube={setCube}
                report={report}
                setNotification={setNotification}
                cubeId={cubeId}
                setError={setCubeError}
                setDebugMessage={setDebugMessage}
            />

            {page.id !== '' && (
                <>
                    <p className="title m-t-10 m-b-5 m-l-10"> </p>
                    <div className="three-tier">
                        <div style={{ minHeight: `calc(100vh - ${navHeight}px)` }} className="left-nav">
                            {page.groups.filter(q => q.isActive).map((item, index) =>
                                <Fragment key={index} >
                                    <div className="section-header">
                                        <ReportSectionLabel group={item} />
                                    </div>
                                    {item.groups.filter(q => q.isActive).map((item2, index2) =>
                                        <div key={index2} className={`section-item ${item2.id === section.id ? 'active' : ''} `} >
                                            <SectionItem onClick={onChangeSection} group={item2} />
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className="content">
                            <div ref={headerRef} >
                                {filters.filter(q => q.level === 1).length > 0 && (
                                    <PageFilters
                                        setNotification={setNotification}
                                        pageProcessingStep={processingStep}
                                        page={page}
                                        report={report}
                                        setPageProcessingStep={setProcessingStep}
                                        setPageFilters={setPageFilters}
                                        cubeAttributes={cubeAttributes}
                                        setDebugMessage={setDebugMessage}
                                    />
                                )}
                                {section.groups.length > 1 && (
                                    <div className="groups">
                                        {section.groups.filter(q => q.isActive).map((item3, index3) =>
                                            <SectionGroup onClick={onChangeGroup} className={`${item3.id === group.id ? 'active' : ''}`} key={index3} group={item3} />
                                        )}
                                    </div>
                                )}
                                <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                                {section.description && <p className="description m-t-10 m-l-10">{`${section.description}`} </p>}

                            </div>
                            {processingStep === ProcessingPage.FatalError && (
                                <OEMessage
                                    className="report-errors"
                                    hideDismissable={true}
                                    message={error}
                                    type={MessageType.Error}
                                />
                            )}
                            {processingStep !== ProcessingPage.FatalError && (
                                <div ref={reportRef} className="report">
                                    {group.id !== '' && (
                                        <>
                                            {group.reports.length === 0 && group.groupType !== ReportGroupType.AboutPage && (
                                                <OEMessage className="h5"
                                                    type={MessageType.Secondary}
                                                    hideDismissable={true}
                                                    message={`There are no reports setup for the report group: <b><i> ${group.title} </b></i> in the section <b><i> ${section.title}</b></i>`} />
                                            )}
                                            {group.groupType === ReportGroupType.Report && group.reports.filter(q => q.isActive).map((item4, index4) =>
                                                <Report
                                                    key={index4}
                                                    reportId={item4.reportId}
                                                    setNotification={setNotification}
                                                    pageFilters={pageFilters}
                                                    pageProcessingStep={processingStep}
                                                    divId={`report${item4.reportId}`}
                                                    configurations={{ ...configurations, reportConfiguration: item4.configuration }}
                                                    pageSettings={pageSettings}
                                                    reportHeight={reportHeight}
                                                    groupType={group.groupType}
                                                    multipleReports={getReportConfigurationValue(ReportConfigurationType.MultipleReports, configurations.groupConfiguration)}
                                                />
                                            )}

                                            {footer && (
                                                <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                            )}
                                        </>
                                    )}
                                    {section.groups.length === 0 && section.id !== '' && (
                                        <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No groups have been set up for ${section.title} `} />
                                    )}
                                </div>
                            )}

                        </div>
                    </div>
                </>
            )}
        </div >
    );
};

export default MSEmbeddedPublic;
