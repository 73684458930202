/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../../core/utilities/String';
import { IReport, canDebugReport, canFilterReport, defaultReport, getReportCubeRequest, getReportLevel } from '../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationPaginationStyles, ReportConfigurationSuppression, ReportConfigurationType, ReportDataDownloadType, getReportConfigurationValue, getReportConfigurationValue2 } from '../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType } from '../../../reporting/entities/ReportGroup';
import { ProcessingPage, ProcessingReport, logProcessingReport, reportLogToConsoleNotification } from '../../../reporting/entities/ReportProcessing';
import { IReportSizing, defaultReportSizing } from '../../../reporting/entities/ReportSizing';
import { useGetReport } from '../../../reporting/services/ReportService';
import { SiteSetting, getSiteSetting } from '../../../site/entities/SiteSettings';
import { IDossierCreation, IDossierCreationFilter, IDossierCreationFilterSelection, IDossierSettings, IMSTRDossierFilter, defaultDossierCreation, defaultDossierSettings } from '../../entities/MicroStrategyDossier';
import { IMicroStrategyExportOptions } from '../../entities/MicroStrategyExportOptions';
import { IMicroStrategyFilter, getDefaultMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { IMicroStrategyDossierChapterList, defaultMicroStrategyDossierChapterList } from '../../entities/MicrostrategyDossierChapter';
import { IMicroStrategyDossierFilter, IMicroStrategyDossierFilterSelection, MicroStrategyDossierFilterType, MicroStrategySingleSelectAllValue, isSuppressionFilter } from '../../entities/MicrostrategyDossierFilter';
import { ICubeRequest, defaultCubeRequest } from '../../entities/api/CubeRequest';
import { ICubeAttribute } from '../../entities/api/CubeResponse';
import { IDossierDefinition, IDossierDefinitionFilter, defaultDossierDefinitionFilter, getDefaultDossierDefinition } from '../../entities/api/DossierDefinition';
import { createDossier, exportDossier, getDossierChapterList, getDossierFilters } from '../../services/MicrostrategyDossierService';
import AboutSection from './AboutSection';
import Cube from './Cube';
import DebugMessage from './DebugMessage';
import DossierFilters from './DossierFilters';
import MicrostrategyPageChapters from './MicrostrategyPageChapters';
import ReportExport from './ReportExport';
import ReportTitle from './ReportTitle';


interface IDossierFilters {
    key: string;
    id: string;
    selection: string;
    filterBy: boolean;
    name: string;
    rangeIncrements: string;
    pageFilter: boolean;
}

const isSelectAll = (i: string): boolean => {
    return i.includes(MicroStrategySingleSelectAllValue);
}

interface IReportInfo {
    reportId: string;
    setNotification: (n: INotification) => void;
    pageFilters: IMicroStrategyFilter[];
    pageProcessingStep: ProcessingPage;
    configurations: IReportConfigurations;
    divId: string;
    groupType: string;
    isPublic?: boolean;
    pageSettings?: IDossierSettings;
    reportHeight: number;
    setFooter?: (i: string) => void;
    aboutPages?: IReportGroup[];
    multipleReports?: boolean;
    hideHeaderTitle?: boolean;
}

const Report: React.FunctionComponent<IReportInfo> = ({
    reportId, setNotification, pageFilters, pageProcessingStep, isPublic, aboutPages,
    configurations, divId, pageSettings, reportHeight,
    groupType, multipleReports, hideHeaderTitle
}) => {
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const exportReportRef = useRef<any>();

    const [dossierDefinition, setDossierDefinition] = useState<IDossierDefinition>(getDefaultDossierDefinition());
    const [message, setMessage] = useState<string>('');
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [debug] = useState<boolean>(canDebugReport());
    const [showFilters] = useState<boolean>(canFilterReport());
    const [initialized, setInitialized] = useState<boolean>(false);
    const [path, setPath] = useState<string>('');
    const [dossier, setDossier] = useState<any>();
    const [report, setReport] = useState<IReport>(defaultReport);
    const [cubeId, setCubeId] = useState<string>('');
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [footer, setFooter] = useState<string>('');

    const [dossierHeight, setDossierHeight] = useState<string>('500px');
    const [reportSizing, setReportSizing] = useState<IReportSizing>(defaultReportSizing);
    const [processingStep, setProcessingStep] = useState<ProcessingReport>(ProcessingReport.Idle);
    const [paginationLinks, setPaginationLinks] = useState<boolean>(false);

    const [chapterList, setChapterList] = useState<IMicroStrategyDossierChapterList>(defaultMicroStrategyDossierChapterList);
    const [chapterIndex, setChapterIndex] = useState<number>(0);
    const [aboutIndex, setAboutIndex] = useState<number>(-1);
    const [exportOptions, setExportOptions] = useState<IMicroStrategyExportOptions[]>([]);
    const [flagging, setFlagging] = useState<boolean>(false);
    const [dataDownload, setDataDownload] = useState<ReportDataDownloadType>(ReportDataDownloadType.DownloadOff);
    const [cubeRequest, setCubeRequest] = useState<ICubeRequest>({ ...defaultCubeRequest });
    const [noDataMessage, setNoDataMessage] = useState<string>('');
    const [suppression, setSuppression] = useState<ReportConfigurationSuppression>(ReportConfigurationSuppression.UserOff);

    const [dossierFilters, setDossierFilters] = useState<IMicroStrategyFilter[]>([]);
    const [localDossierFilters, setLocalDossierFilters] = useState<IDossierFilters[]>([]);

    useEffect(() => {
        switch (pageProcessingStep) {
            case ProcessingPage.PageFiltersLoaded:
            case ProcessingPage.GroupLoaded:
                setMessage('Initializing Report');
                break;

            case ProcessingPage.UpdateDossierFilters:
            case ProcessingPage.PageFiltersUpdated:
                setProcessingStep(ProcessingReport.ReportLoad);
                break;

            case ProcessingPage.FatalError:
                setProcessingStep(ProcessingReport.FatalError);
                setMessage('');
                break;
        }
        // eslint-disable-next-line
    }, [pageProcessingStep]);

    useEffect(() => {
        if (report.id !== reportId && !emptyGUID(reportId) && pageProcessingStep === ProcessingPage.Complete) {
            setProcessingStep(ProcessingReport.ReportLoad);
        }
        // eslint-disable-next-line
    }, [reportId]);

    useEffect(() => {
        setSuppression(getReportConfigurationValue(ReportConfigurationType.Suppression, configurations.sectionTitleConfiguration, configurations.pageConfiguration));
        // eslint-disable-next-line
    }, [configurations]);

    useEffect(() => {
        logProcessingReport(processingStep, true);
        switch (processingStep) {
            case ProcessingReport.ReportLoad:
                if (reportId !== report.id || emptyGUID(report.id)) {
                    setGetReportId(reportId);
                    setChapterList(defaultMicroStrategyDossierChapterList);
                    setExportOptions([]);
                    setProcessingStep(ProcessingReport.ReportLoading);
                } else {
                    setProcessingStep(ProcessingReport.ReportLoaded);
                }
                break;

            case ProcessingReport.ReportLoaded:
                setProcessingStep(ProcessingReport.CubeLoad);
                setDataDownload(getReportConfigurationValue(ReportConfigurationType.DataDownload, configurations.pageConfiguration));
                setFlagging(!isPublic && getReportConfigurationValue(ReportConfigurationType.ReportFlagging, configurations.pageConfiguration));
                setNoDataMessage(getReportConfigurationValue(ReportConfigurationType.NoDataMessage, report.configuration, configurations.pageConfiguration));
                break;

            case ProcessingReport.CubeLoad:
                if (cubeAttributes.length === 0) {
                    setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
                } else {
                    setProcessingStep(ProcessingReport.CubeLoaded);
                }
                break;

            case ProcessingReport.CubeLoaded:
                setProcessingStep(ProcessingReport.DossierFiltersLoad);
                break;

            case ProcessingReport.DossierFiltersLoaded:
            case ProcessingReport.PageChangedFiltersLoaded:
                const f: IDossierFilters[] = [];
                for (const d of dossierDefinition.chapters[chapterIndex].filters) {
                    const match: IDossierFilters = getMatchingFilter(d);
                    if (match.id !== '') {
                        f.push(match);
                    }
                }
                setLocalDossierFilters(f);
                break;

            case ProcessingReport.DossierCreate:
                if (dossier) {
                    setProcessingStep(ProcessingReport.DossierFiltersApply);
                } else {
                    setMessage('Loading Report');
                    onCreateDossier();
                }
                break;

            case ProcessingReport.DossierFiltersApply:
                setMessage('Applying Filters to Report');
                applyDossierFilters();
                break;

            case ProcessingReport.NoDataFound:
                setMessage('');
                break;

            case ProcessingReport.Complete:
                debug && console.log('dossier', dossier);
                setMessage('');
                setInitialized(true);
                setProcessingStep(ProcessingReport.Idle);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (processingStep === ProcessingReport.CubeLoad) {
            setProcessingStep(ProcessingReport.CubeLoaded);
        }
        // eslint-disable-next-line
    }, [cubeAttributes]);

    useEffect(() => {
        if (groupType !== ReportGroupType.AboutPage && report.id !== '' && pageProcessingStep === ProcessingPage.Complete) {
            setProcessingStep(ProcessingReport.ReportLoad);
        }
        // eslint-disable-next-line
    }, [groupType]);

    useEffect(() => {
        let b: boolean = false;
        for (const o of exportOptions) {
            if (o.export) {
                const i: string[] = report.externalId.split(/\//);
                exportDossier(`https://${path.split(/\//)[2]}`, 'MicroStrategyLibrary', i[0], i[1], dossier.instanceId, o.filename, o.type.toString());
                o.export = false;
                b = true;
                setNotification({ message: `Export is processing....`, type: 'info' })
            }
        }
        if (b) {
            setExportOptions([...exportOptions]);
        }
        // eslint-disable-next-line
    }, [exportOptions]);

    useEffect(() => {
        if (reportService.result) {
            const heightOverride: string = getReportConfigurationValue(ReportConfigurationType.MinReportHeightOverride, reportService.result.report.configuration);
            const widthOverride: string = getReportConfigurationValue(ReportConfigurationType.MinReportWidthOverride, reportService.result.report.configuration);
            setCubeRequest({ ...getReportCubeRequest(reportService.result.report), tokenId: cubeRequest.tokenId });
            setReport(reportService.result.report);
            setPath(isPublic ? `${getSiteSetting(SiteSetting.MSPublicPath)}/app` : reportService.result.reportServer.path);
            setPaginationLinks(getReportConfigurationValue(ReportConfigurationType.PaginationStyle, configurations.pageConfiguration, configurations.groupConfiguration) === ReportConfigurationPaginationStyles.Links);
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, reportService.result.report.configuration));
            setSuppression(getReportConfigurationValue(ReportConfigurationType.Suppression, configurations.sectionTitleConfiguration, configurations.pageConfiguration));
            setReportSizing({
                minHeight: heightOverride !== '0' ? `${heightOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                maxHeight: `${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                minWidth: widthOverride !== '0' ? `${widthOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                maxWidth: `${getReportConfigurationValue(ReportConfigurationType.MaxReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`

            });
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        const count: number = chapterList.chapters.filter(q => !q.hidden).length;
        const heightOverride: string = getReportConfigurationValue(ReportConfigurationType.MinReportHeightOverride, report.configuration);
        const widthOverride: string = getReportConfigurationValue(ReportConfigurationType.MinReportWidthOverride, report.configuration);
        if (count <= 1) {
            setReportSizing({
                minHeight: heightOverride !== '0' ? `${heightOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportHeight, report.configuration, configurations.groupConfiguration, configurations.pageConfiguration)}px`,
                maxHeight: `${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, report.configuration, configurations.groupConfiguration, configurations.pageConfiguration)}px`,
                minWidth: widthOverride !== '0' ? `${widthOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportWidth, report.configuration, configurations.groupConfiguration, configurations.pageConfiguration)}px`,
                maxWidth: `${getReportConfigurationValue(ReportConfigurationType.MaxReportWidth, report.configuration, configurations.groupConfiguration, configurations.pageConfiguration)}px`

            });
        }
        else {
            setReportSizing({
                minHeight: heightOverride !== '0' ? `${heightOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportHeight, configurations.pageConfiguration, configurations.groupConfiguration, report.configuration)}px`,
                maxHeight: `${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, configurations.pageConfiguration, configurations.groupConfiguration, report.configuration)}px`,
                minWidth: widthOverride !== '0' ? `${widthOverride}px` : `${getReportConfigurationValue(ReportConfigurationType.MinReportWidth, configurations.pageConfiguration, configurations.groupConfiguration, report.configuration)}px`,
                maxWidth: `${getReportConfigurationValue(ReportConfigurationType.MaxReportWidth, configurations.pageConfiguration, configurations.groupConfiguration, report.configuration)}px`

            });
        }
        // eslint-disable-next-line
    }, [chapterList]);

    useEffect(() => {
        if (processingStep === ProcessingReport.ReportLoading) {
            setProcessingStep(ProcessingReport.ReportLoaded);
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (processingStep === ProcessingReport.PageChangedFiltersLoaded || processingStep === ProcessingReport.DossierFiltersLoaded) {
            setProcessingStep(ProcessingReport.DossierCreate);
            onUpdateHeight();
        }
        // eslint-disable-next-line
    }, [localDossierFilters]);

    useEffect(() => {
        if (processingStep === ProcessingReport.PageChanged) {
            setProcessingStep(ProcessingReport.PageChangedFiltersLoaded);
            onUpdateHeight();
        }
        // eslint-disable-next-line
    }, [dossierFilters]);

    useEffect(() => {
        onUpdateHeight();
        // eslint-disable-next-line
    }, [reportHeight, chapterList]);

    const onUpdateHeight = () => {
        const percent: number = getReportConfigurationValue(ReportConfigurationType.ReportHeightPercentage, configurations.reportConfiguration);
        if (percent === 100) {
            setDossierHeight(`calc(100vh - ${reportHeight + (dossierFilters.length > 0 ? 10 : 0) + (chapterList.totalPages > 1 ? 20 : 0)}px)`);
        } else {
            setDossierHeight(`calc(${percent}% - ${reportHeight}px)`);
        }
    }

    const getMatchingFilter = (d: IDossierDefinitionFilter): IDossierFilters => {
        // find in page filters
        for (const p of pageFilters) {
            if (p.key === d.source.id) {
                return {
                    id: p.key,
                    key: d.key,
                    selection: p.selection,
                    filterBy: true,
                    name: d.name,
                    pageFilter: true,
                    rangeIncrements: p.rangeIncrements
                };
            }
            const i: string[] = p.externalId.split(',');
            for (const s of i) {
                if (s.trim().toLowerCase() === d.name.trim().toLowerCase()) {
                    return {
                        id: d.source.id,
                        key: d.key,
                        selection: p.selection,
                        filterBy: true,
                        name: d.name,
                        pageFilter: true,
                        rangeIncrements: p.rangeIncrements
                    };
                }
            }
        }

        // find in dossier filters
        let match = dossierFilters.find(q => q.key === d.source.id);
        if (match) {
            return {
                id: match.key,
                key: d.key,
                selection: match.selection,
                filterBy: true,
                name: d.name,
                pageFilter: false,
                rangeIncrements: "0"
            };
        }

        match = dossierFilters.find(q => q.name === d.name);
        if (match) {
            return {
                id: match.key,
                key: d.key,
                selection: match.selection,
                filterBy: true,
                name: d.name,
                pageFilter: false,
                rangeIncrements: "0"
            };
        }
        if (isSuppressionFilter(d.name)) {
            match = dossierFilters.find(q => q.isSuppression);
            if (match) {
                return {
                    id: match.key,
                    key: d.key,
                    selection: match.selection,
                    filterBy: true,
                    name: d.name,
                    pageFilter: false,
                    rangeIncrements: "0"
                };
            }
        }

        return { id: '', key: '', selection: '', filterBy: false, name: '', pageFilter: false, rangeIncrements: "0" };
    }

    const onCreateDossier = () => {
        if (!emptyGUID(report.id) && path !== '') {
            const dossierCreate: IDossierCreation = {
                ...defaultDossierCreation,
                dossierChanged: onDossierChanged,
                debug,
                divId,
                showFilters,
                errorHandler: onDossierError,
                url: `${path}/${report.externalId}`,
                settings: {
                    ...defaultDossierSettings,
                    showNavigationBar: getReportConfigurationValue(ReportConfigurationType.ShowNavgiationBar, configurations.reportConfiguration, report.reportConfiguration),
                    showFilters: getReportConfigurationValue(ReportConfigurationType.ShowFilters, configurations.reportConfiguration, report.reportConfiguration),
                    hideFilterSummary: getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.reportConfiguration, report.reportConfiguration) || (pageSettings ? pageSettings.hideFilterSummary : true)
                },
                pageRendered: onDossierPageRendered,
                pageSwitched: onDossierPageSwitched,
            };

            dossierCreate.config.filters = [];
            for (const dossierFilter of localDossierFilters.filter(q => q.filterBy)) {
                if (!isSuppressionFilter(dossierFilter.name)) {
                    const creationFilter: IDossierCreationFilter = getDossierFilterSelectionForCreate(dossierFilter);
                    if (creationFilter.selections.length > 0) {
                        dossierCreate.config.filters.push(creationFilter);
                    }
                }
                else {
                    try {
                        let mf: IMicroStrategyFilter = getDefaultMicroStrategyFilter();
                        let ddf: IDossierDefinitionFilter = defaultDossierDefinitionFilter;
                        for (const c of dossierFilters) {
                            if (isSuppressionFilter(c.key)) {
                                mf = c;
                            }
                        }
                        for (const c of dossierDefinition.chapters[0].filters) {
                            if (isSuppressionFilter(c.name)) {
                                ddf = c;
                            }
                        }
                        if (mf.key !== '' && ddf.key !== '') {
                            const key: string = `h${mf.value};${ddf.source.id}`;
                            dossierCreate.config.filters.push({ key: ddf.key, selections: [{ id: key }] });
                        }
                    }
                    catch {
                    }

                }
            }

            debug && console.log('filters passed to dossier', dossierCreate);
            //isPublic ? createPublicDossier(setDossier, dossierCreate) : createDossier(setDossier, { ...dossierCreate, baseUrl: `https://${path.split(/\//)[2]}` });
            createDossier(setDossier, { ...dossierCreate, baseUrl: `https://${path.split(/\//)[2]}` });
        }
    }

    useEffect(() => {
        if (dossier) {
            if (processingStep === ProcessingReport.DossierCreate) {
                getDossierChapterList(updateChapterList, dossier);
                setProcessingStep(ProcessingReport.Complete);
            }
        }
        // eslint-disable-next-line
    }, [dossier]);

    const updateChapterList = (e: IMicroStrategyDossierChapterList) => {
        const listConfiguration: string = getReportConfigurationValue(ReportConfigurationType.ChapterList, configurations.pageConfiguration, report.configuration);
        const excludeConfigurationLevel: string = getReportConfigurationValue(ReportConfigurationType.ChapterExclusionList, configurations.pageConfiguration);
        const excludeConfigurationList: string = getReportConfigurationValue2(ReportConfigurationType.ChapterExclusionList, configurations.pageConfiguration);

        console.log(excludeConfigurationLevel);
        console.log(excludeConfigurationList);
        if (listConfiguration !== '') {
            for (const c of e.chapters) {
                c.hidden = true;
                c.sort = 99;
            }
            let i: number = 1;
            for (const s of listConfiguration.split(';')) {
                for (const ch of e.chapters) {
                    for (const cl of ch.children) {
                        if (cl.name.toLowerCase().trim() === s.toLowerCase().trim()) {
                            ch.hidden = false;
                            ch.sort = i;
                            i = i + 1;
                        }
                    }
                }
            }
            if (getReportLevel(pageFilters) === excludeConfigurationLevel) {
                for (const s of excludeConfigurationList.split(';')) {
                    for (const ch of e.chapters) {
                        for (const cl of ch.children) {
                            if (cl.name.toLowerCase().trim() === s.toLowerCase().trim()) {
                                ch.hidden = true;
                                ch.sort = i;
                                i = i + 1;
                            }
                        }
                    }
                }
            }
            if (e.chapters.filter(q => !q.hidden).length === 0) {
                for (const c of e.chapters) {
                    c.hidden = false;
                    c.sort = 99;
                }
            }
        }
        setChapterList(e);
    }

    const onDossierError = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `onDossierError  (Report)${e.message}`, type: 'error' });
    }

    const onDossierChanged = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `Instance ${e}`, type: 'info' });
    }

    const onDossierPageRendered = (e: any) => {
        reportLogToConsoleNotification(debug);
    }

    const onDossierPageSwitched = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_PAGE_SWITCHED`, type: 'info' });
        setProcessingStep(ProcessingReport.PageChanged);
    }

    const getFilterSelection = (selection: string, id: string, rawValue: string): string => {
        try {
            if (selection === '') {
                // look for selection in cube attributes
                let match = cubeAttributes.find(q => q.id === id);
                if (match) {
                    if (match.elements.find(q => q.key.startsWith(rawValue))) {
                        const m = match.elements.filter(q => q.key.startsWith(rawValue));
                        return m[0].key;
                    }
                }
                setProcessingStep(ProcessingReport.NoDataFound);
            }
            if (selection.includes(id)) {
                // check for match in cube attributes
                let match = cubeAttributes.find(q => q.id === id);
                if (match) {
                    if (!match.elements.find(q => q.key === selection)) {
                        setProcessingStep(ProcessingReport.NoDataFound);
                    }
                }
                return selection;
            }
            const i: string[] = selection.split(';');
            return `${i[0]};${id}`;
        }
        catch {
            return '';
        }
    }

    const getDossierFilterSelectionForCreate = (f: IDossierFilters): IDossierCreationFilter => {
        const v: IDossierCreationFilterSelection[] = [];
        let s: string = ''
        try {
            let match = pageFilters.find(q => q.key === f.id);
            if (!match) match = dossierFilters.find(q => q.name === f.name);
            if (match) {
                switch (match.filterType) {
                    case MicroStrategyDossierFilterType.MultipleSelect:
                        for (const c of match.selection.split(',')) {
                            s = getFilterSelection(c, f.id, match.value);
                            if (s !== '') { v.push({ id: s }); }
                        }
                        break;

                    default:
                        s = getFilterSelection(match.selection, f.id, match.value);
                        if (s !== '') { v.push({ id: s }); }
                        break;
                }
            }
        }
        catch (e) {
            setNotification({ message: `ERROR====>> getDossierFilterSelectionForCreate : ${e}`, type: 'error' })
        }

        return { key: f.key, selections: v };
    }
    const onUpdateFilters = (d: IMSTRDossierFilter[]) => {
        if (d && d.length > 0) {
            dossier.filters = d;
            const filterList: IMicroStrategyDossierFilter[] = [];
            dossier.currentChapterIndex = chapterIndex;
            for (const f of localDossierFilters.filter(q => q.filterBy)) {
                let match = pageFilters.find(q => q.key === f.id);
                if (!match) {
                    match = dossierFilters.find(q => q.key === f.id);
                }
                else {
                    if (match.selection === '') {
                        setProcessingStep(ProcessingReport.NoDataFound);

                        return;
                    }
                }
                if (match) { filterList.push(getDossierFilterSelectionForApply(f, match)); }
            }
            try {
                for (const f of filterList) {
                    try {
                        if (chapterIndex > 0) {
                            let match = dossierDefinition.chapters[chapterIndex].filters.filter(q => q.name === f.name);
                            if (match) {
                                switch (f.type) {
                                    case MicroStrategyDossierFilterType.SingleSelectWithAll:
                                    case MicroStrategyDossierFilterType.MultipleSelect:
                                        if (f.multipleSelect) {
                                            f.multipleSelect.filterInfo.key = match[0].key;
                                        }
                                        break;
                                    default:
                                        if (f.singleSelect) {
                                            f.singleSelect.filterInfo.key = match[0].key;
                                        }
                                        break;
                                }
                            }
                        }
                        switch (f.type) {
                            case MicroStrategyDossierFilterType.Range:
                                let df = d.find(q => q.filterKey === f.singleSelect?.filterInfo.key);
                                let start: number = f.start || 0;
                                let end: number = f.end || 0;
                                if (df) {
                                    end = df.filterDetail.items.findIndex(q => q.value === f.selection);
                                    start = end - (f.increments || 2);
                                }
                                dossier.filterAttributeMultiSlider({
                                    filterInfo: { key: f.singleSelect?.filterInfo.key }, selections: [start < 0 ? 0 : start, end < 0 ? 2 : end]
                                });
                                break;

                            case MicroStrategyDossierFilterType.SingleSelectWithAll:
                            case MicroStrategyDossierFilterType.MultipleSelect:
                                dossier.filterSearchMultiAttributes(f.multipleSelect);
                                break;

                            default:
                                dossier.filterSelectSingleAttribute(f.singleSelect);
                                break;
                        }
                    }
                    catch (e) {
                        setNotification({ message: `ERROR====>> applyDossierFilters (setting filter ${f.name} : ${e}`, type: 'error' })
                    }
                }
                dossier.filterApplyAll();
            }
            catch (e) {
                setNotification({ message: `ERROR====>> applyDossierFilters : ${e}`, type: 'error' })
            }
            setProcessingStep(ProcessingReport.Complete);
        }
    }

    const applyDossierFilters = () => {
        if (dossier) {
            getDossierFilters(onUpdateFilters, dossier);
        }
    };

    const getDossierFilterSelectionForApply = (f: IDossierFilters, rf: IMicroStrategyFilter): IMicroStrategyDossierFilter => {
        try {
            let df = dossier.filters.find((q: { filterName: string; }) => q.filterName === f.name);
            const key: string = df ? df.filterKey : f.key;
            const type: string = getFilterType(df);
            switch (type) {
                case MicroStrategyDossierFilterType.Range:
                    return {
                        type,
                        start: 0,
                        end: 1,
                        increments: parseInt(f.rangeIncrements || '2', 10),
                        singleSelect: { filterInfo: { key, }, holdSubmit: true, selection: { value: rf.selection } },
                        name: f.name,
                        selection: f.selection
                    }

                case MicroStrategyDossierFilterType.MultipleSelect:
                    const match: string[] = rf.selection.split(',');
                    const selections: IMicroStrategyDossierFilterSelection[] = [];
                    for (const m of match) {
                        selections.push({ value: m });
                    }
                    return {
                        type,
                        multipleSelect: { selections, filterInfo: { key, }, holdSubmit: true },
                        name: f.name,
                        selection: f.selection
                    };

                case MicroStrategyDossierFilterType.SingleSelectWithAll:
                    const allSelections: IMicroStrategyDossierFilterSelection[] = [];
                    if (isSelectAll(rf.selection)) {
                        for (const m of rf.values) {
                            !isSelectAll(m.id) && allSelections.push({ value: m.id });
                        }
                    }
                    else {
                        allSelections.push({ value: rf.selection });
                    }
                    return {
                        type,
                        multipleSelect: { selections: allSelections, filterInfo: { key, }, holdSubmit: true },
                        name: f.name,
                        selection: f.selection
                    };

                default:
                    if (isSuppressionFilter(f.name)) {
                        return {
                            singleSelect: {
                                filterInfo: { key, },
                                holdSubmit: true,
                                selection: { name: rf.value === '1' || rf.value.startsWith('h1') ? '1' : '0' }
                            },
                            name: f.name,
                            type: MicroStrategyDossierFilterType.SingleSelect,
                            selection: f.selection
                        };
                    }

                    return {
                        singleSelect: { filterInfo: { key, }, holdSubmit: true, selection: { value: rf.selection } },
                        name: f.name,
                        type: MicroStrategyDossierFilterType.SingleSelect,
                        selection: f.selection
                    }
            }
        }
        catch {
            setNotification({ message: `Could not apply dossier filter ${rf.name} (Report)`, type: 'error' })
            return {
                singleSelect: { filterInfo: { key: '', }, holdSubmit: true, selection: { value: '' } },
                name: '', type: getFilterType(rf), selection: f.selection
            }
        }
    }

    const getFilterType = (df: any): MicroStrategyDossierFilterType => {
        try {
            switch (df.filterType) {
                case 'attributeSlider':
                    return MicroStrategyDossierFilterType.Range;
                default:
                    return df.filterDetail.supportMultiple ? MicroStrategyDossierFilterType.MultipleSelect : MicroStrategyDossierFilterType.SingleSelect;
            }
        }
        catch {
            return MicroStrategyDossierFilterType.SingleSelect;
        }
    }

    const onExportReport = (i: IMicroStrategyExportOptions) => {
        exportOptions.filter(q => q.type === i.type)[0].export = true;
        setExportOptions([...exportOptions]);
    }

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
    }

    const addDossierFilters = () => {
        // setProcessingStep(ProcessingReport.DossierFiltersInitialize);

    }

    const setCubeError = (e: string) => {
        setError(`Cube Load Error: ${e}`);
        setProcessingStep(ProcessingReport.FatalError);
    }

    const isReportValid = (): boolean => {
        switch (processingStep) {
            case ProcessingReport.FatalError:
            case ProcessingReport.ChapterListEmpty:
                return false;

            default:
                return true;
        }
    }

    return (
        <>
            <Cube
                validToken={true}
                setCube={setCube}
                report={report}
                setNotification={setNotification}
                cubeId={cubeId}
                setError={setCubeError}
                setDebugMessage={setDebugMessage}
            />

            <div className={`${paginationLinks ? 'report-title-links' : 'report-title'}`}>
                {!hideHeaderTitle && (
                    <div className='report-title-container'>
                        <div className='flex-content'>
                            {(multipleReports || (report.title)) && (
                                <ReportTitle report={report} dataDownload={dataDownload} flagging={flagging} pageFilters={pageFilters} />
                            )}
                        </div>
                        {isReportValid() && (
                            <>
                                {paginationLinks && (
                                    <div className="text-right" >
                                        <MicrostrategyPageChapters
                                            onUpdateFilters={addDossierFilters}
                                            chapterList={chapterList}
                                            dossier={dossier}
                                            setNotification={setNotification}
                                            aboutPages={aboutPages}
                                            reportID={report.id}
                                            aboutIndex={aboutIndex}
                                            setAboutIndex={setAboutIndex}
                                            setCurrentChapterIndex={setChapterIndex}
                                            tabs={false}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {isReportValid() && (
                            <>
                                {exportOptions.map((i, index) =>
                                    <ReportExport exportOption={i} key={index} exportReport={onExportReport} />
                                )}
                            </>
                        )}
                    </div>
                )}
                <DebugMessage debug={debug} small={true} >
                    <span >{`${processingStep} - ${debugMessage} (${chapterIndex} of ${chapterList.chapters.length})`}</span>
                    <span className="m-l-10 pull-right">
                        {`Height: ${reportSizing.maxHeight}`}
                        {` Report Height: ${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, report.configuration)}px`}
                        {` Page Height: ${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, configurations.pageConfiguration)}px`}
                    </span>
                </DebugMessage>

                <div className='spinner-container'>
                    <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
                </div>
                {!isReportValid() && (
                    <OEMessage
                        className="report-errors"
                        hideDismissable={true}
                        message={error}
                        type={MessageType.Error}
                    />
                )}
                {isReportValid() && (
                    <>
                        {!paginationLinks && (
                            <MicrostrategyPageChapters
                                onUpdateFilters={addDossierFilters}
                                chapterList={chapterList}
                                dossier={dossier}
                                setNotification={setNotification}
                                aboutPages={aboutPages}
                                reportID={report.id}
                                aboutIndex={aboutIndex}
                                setAboutIndex={setAboutIndex}
                                setCurrentChapterIndex={setChapterIndex}
                                tabs={true}
                            />
                        )}

                        <DossierFilters
                            setNotification={setNotification}
                            reportProcessingStep={processingStep}
                            report={report}
                            setReportProcessingStep={setProcessingStep}
                            pageFilters={pageFilters}
                            setDossierFilters={setDossierFilters}
                            cubeAttributes={cubeAttributes}
                            setDebugMessage={setDebugMessage}
                            setPageDossierDefinition={setDossierDefinition}
                            chapterIndex={chapterIndex}
                            chapterList={getReportConfigurationValue(ReportConfigurationType.ChapterList, configurations.pageConfiguration, report.configuration)}
                            suppression={suppression}
                        />

                        {aboutPages && aboutIndex >= 0 && (
                            <AboutSection group={aboutPages[aboutIndex]} />
                        )}

                        {groupType !== ReportGroupType.AboutPage && (
                            <div>
                                <div ref={exportReportRef} style={{
                                    marginBottom: '1px',
                                    marginTop: '5px',
                                    height: processingStep === ProcessingReport.NoDataFound ? '0px' : dossierHeight,
                                    minHeight: initialized ? reportSizing.minHeight : 0,
                                    maxHeight: initialized ? reportSizing.maxHeight : 0,
                                    minWidth: initialized ? reportSizing.minWidth : 0,
                                    maxWidth: initialized ? reportSizing.maxWidth : 0
                                }}>
                                    <div className={`${initialized ? 'three-tier-open' : 'three-tier-closed'}`} id={divId} />
                                </div>
                            </div>
                        )}
                        {footer && (
                            <span className="report-footer" dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                        )}

                        {processingStep === ProcessingReport.NoDataFound && (
                            <div className="m-t-10 m-b-40">
                                <div dangerouslySetInnerHTML={displayHTMLContent(noDataMessage)} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Report;
